import React from "react";
import ReactDOM from "react-dom";
import ChangeStatusProgressFooter from "../MainPage/NotWorkArea(Side&Head)/ChangeStatusProgress";
// import Tree from "../MainPage/Windows/ViewData/Tree/tree";
import ModalProgress from "./components/ModalProgress";
import { AxiosRequest, XMLrequest } from "../Url";
import {
  GetJsonAttributeByID,
  getModalSectionID,
  getStyleWindow,
  isEmptyObject,
  outPutShow,
  updateSection,
} from "../MainPage/Tools/Tools";
import * as mime from "react-native-mime-types";
import FormsMainFile from "../MainPage/Sections/ElementsSections/Forms/FormsMainFile";
import ModalWindow from "../MainPage/Module/ModalWindow";
import MessageBox from "../MainPage/Module/MessageBox";
import DialogHtml from "../Forms/DialogHtml";
import DialogBook from "../MainPage/Module/Dialogs/DialogBook";
import DialogCert from "../MainPage/Module/Dialogs/DialogCert";
import { store } from "../../store";
import { pushSideBar, setSelectedSlice } from "../../store/SideBarSlice";
import WinForText from "../MainPage/Windows/WinForText";
import DialogKldr from "../MainPage/Module/Dialogs/DialogKldr";
import DialogOrganizations from "../MainPage/Module/Dialogs/DialogOrganisations";
import HandleTable from "./components/HandleTable";
import { Button, Grid } from "@mui/material";
import TestTree from "../MainPage/Windows/ViewData/Tree/testTree";
import SignDialog from "../MainPage/Module/Dialogs/SignDialog";
import TestParams from "../MainPage/Sections/ElementsSections/testParams";
import DialogSetObjText from "../MainPage/Module/Dialogs/DialogSetObjText";
import DialogWithTextField from "../MainPage/Module/Dialogs/DialogWithTextField";
import { CheckWorkSpace } from "../Singin/SingIn";
import TextAreaWithButtons from "../MainPage/Module/Dialogs/Components/TextAreaWithButtons";
import { openModal } from "../MainPage/Tools/modalManager";
import DialogParams from "../MainPage/Module/Dialogs/DialogParams";
import { TITLE_MESSAGE_MODAL_WINDOW } from "../MainPage/Tools/ProjectX";

export function CheckModalBackGrounds() {
  const Arr = document.getElementsByClassName("background_ModalDialog");
  const muiDialogs = document.getElementsByClassName("MuiDialog-root");
  let count = 0;
  for (const value of Object.values(Arr)) {
    if (value && (!value.id || !value.id.includes("loadmask"))) {
      count++;
    }
  }
  return Number(count) + muiDialogs.length;
}

export function RemoveAllBG() {
  const Arr = document.getElementsByClassName("background_ModalDialog");
  if (Arr && Arr.length > 0) {
    for (const value of Object.values(Arr)) {
      value.remove();
    }
  }
}

export function CreateBackgroundForModal(elem, id, zIndex) {
  let background3 = document.createElement("div");
  background3.classList.add("background_ModalDialog");
  background3.style.height = "100%";
  background3.id = id === undefined ? "" : id;
  background3.style.width = "100%";
  background3.style.position = "absolute";
  background3.style.top = "0px";
  background3.style.background = "";
  // background3.style.backdropFilter = "blur(2px)";

  if (id && id.includes("loadmask")) background3.style.backdropFilter = "";
  background3.style.zIndex = `${
    zIndex === undefined ? 1000 + CheckModalBackGrounds() : zIndex + CheckModalBackGrounds()
  }`;
  ReactDOM.render(elem, background3);
  document.body.appendChild(background3);
  return background3;
}

export function RenderMessageBox(DlgType, Buttons, Message, closeF, selectF, func, from) {
  // 0 - Предупреждение, 1- Ошибка, 2 - Информация, 3 = Подтверждение,
  openModal(
    <MessageBox
      DlgType={DlgType}
      Message={Message}
      Buttons={Buttons}
      onSelect={selectF}
      setReturnValue={func}
      from={from}
    />,
    {
      style: getStyleWindow("MessageBox"),
      hiddenButton: true,
      blockMaximize: true,
      blockSaveLocation: true,
      blockResize: true,
      Title: TITLE_MESSAGE_MODAL_WINDOW[DlgType],
      onClose: () => {
        closeF?.({ target: { value: "2" } }, undefined, func, from);
      },
    },
  );
}

var funcGlobal;

function buildFileSelector(info, requestID, subData) {
  const fileSelector = document.createElement("input");
  fileSelector.setAttribute("type", "file");
  fileSelector.setAttribute("accept", info.filter || "file");
  fileSelector.setAttribute("multiple", info.multiple);
  fileSelector.addEventListener("change", async (e) => {
    const files = Array.from(e.target.files); //сохраняем коллекцию файлов
    const params = new Map();
    params.set("prefix", "project");
    params.set("comand", "ResumeRequest");
    params.set("RequestID", requestID);
    params.set("WSM", "1");
    const filesNames = files.map((file) => file.name).join("\n"); //сюда достаем имена файлов, что бы потом через join получить имена
    const filesData = await Promise.all(files.map((file) => getBase64ByFileReader(file)));
    const finalData = filesData.reduce(
      (acc, rcdata, index) => ({
        ...acc,
        [files[index].name]: {
          size: files[index].size,
          rcdata,
        },
      }),
      {},
    );
    const json = await AxiosRequest(true, params, { FileName: filesNames, Result: "1", $content: filesNames });
    tokenProcessingTest(json, { ...subData, props: { ...subData.props, files: finalData } });
  });
  fileSelector.addEventListener("cancel", () => {
    resumeRequest(requestID, subData);
  });
  fileSelector.click();
}

export const getBase64ByFileReader = (file) => {
  const reader = new FileReader();
  return new Promise((resolve) => {
    reader.onload = (ev) => {
      const rcdata = ev.target.result.split(","); //нам нужна только часть с base64, без mime информации
      resolve(rcdata[1]);
    };
    reader.readAsDataURL(file);
  });
};

function InputTextChange(Text, RequestID, event, func) {
  let params = new Map(),
    data,
    json;
  //const data1 = new FormData(event.currentTarget);

  if (event === false) {
    data = { Result: "" };
  } else {
    data = { Text: Text, Result: 1 };
  }
  params.set("prefix", "project");
  params.set("comand", "ResumeRequest");
  params.set("RequestID", RequestID);
  params.set("WSM", "1");
  json = XMLrequest(params, data);
  tokenProcessingTest(json, { func: func });
}

const WORKSPACE_REQUEST_TYPE = "workspace-request";
const WORKSPACE_RESPONSE_TYPE = "workspace-response";

export function executeCertList(RequestID) {
  return window.webkcrypt.plain_text_certificate_list().value;

  return sendRequest({
    type: WORKSPACE_REQUEST_TYPE,
    requestId: RequestID, // уникальный UUID запроса
    params: {
      command: "certList",
    },
  });
}

function executeDetachedTest(sn, data) {
  return window.webkcrypt.make_pkcs7_signature(sn, data, true).value;

  return sendRequest({
    type: WORKSPACE_REQUEST_TYPE,
    requestId: RequestID, // уникальный UUID запроса
    params: {
      command: "sign",
      data: data,
      format: "CMS",
      serialNumber: sn,
      type: "detached",
    },
  });
}

function handleMessageList(response, subData, RequestID) {
  // if (WORKSPACE_RESPONSE_TYPE === messageEvent.data.type) {
  // const response = messageEvent.data;
  if (response !== "") {
    // if (response.successful === false) {
    //   RenderMessageBox(1, 1, response.result, InputButton, funcGlobal);
    // } else {
    sendCertListWSM(response, subData, RequestID);
    // }
  } else {
    sendCertListWSM(undefined, subData, RequestID);
  }
  // }
}

function sendCertListWSM(res, subData, RequestID) {
  let params = new Map(),
    data,
    json;
  data = { res: res ? res : "" };
  params.set("prefix", "project");
  params.set("comand", "ResumeRequest");
  params.set("RequestID", RequestID);
  params.set("WSM", "1");
  json = XMLrequest(params, data);
  tokenProcessingTest(json, subData);
}

function handleMessageSign(message, RequestID, subData) {
  let params = new Map();
  params.set("prefix", "project");
  params.set("comand", "ResumeRequest");
  params.set("RequestID", RequestID);
  params.set("WSM", "1");
  // const json = XMLrequest(params, {"RCDATA":response.result});
  // tokenProcessingTest(json, funcGlobal);
  let rcdata = message;
  // if (typeof response.result === "string") {
  //   rcdata = response.result;
  // } else if (typeof response.result.result === "string") {
  //   rcdata = response.result.result;
  // }
  AxiosRequest(true, params, { RCDATA: rcdata }).then((res) => {
    tokenProcessingTest(res, subData);
  });
}

export function sendRequest(request, handler) {
  window.postMessage(request, handler === undefined ? "*" : handler);
}

export function uuid() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

class WebkcryptCallback {
  constructor(uuid, callback) {
    this.callback = callback;
    this.uuid = uuid;
    this.handle = (event) => {
      var handled = false;
      try {
        if (event && event.data) {
          if ("type" in event.data && event.data.type == "webkcrypt-reply") {
            if ("uuid" in event.data && event.data.uuid == this.uuid) {
              handled = this.callback(event);
              if (handled) {
                window.removeEventListener("message", this.handle);
              }
            }
          }
        }
        return handled;
      } catch {}
    };
  }
}

function webkcryptdata(method, args) {
  var data = {
    type: "webkcrypt-invoke",
    uuid: uuid(),
    invoke: {
      method: method,
    },
  };
  if (args) {
    data.invoke.args = args;
  }
  return data;
}

export function webkcrypt_invoke(method, args, callback) {
  var data = webkcryptdata(method, args);
  if (callback) {
    window.addEventListener("message", new WebkcryptCallback(data.uuid, callback).handle, false);
  }
  window.postMessage(data, "*");
}

export async function resumeRequest(RequestID, subData, datas) {
  let params = new Map(),
    data,
    json;
  data = { Result: datas ? datas : "" };
  params.set("prefix", "project");
  params.set("comand", "ResumeRequest");
  params.set("RequestID", RequestID);
  params.set("WSM", "1");
  json = await AxiosRequest(true, params, datas ? datas : data);
  tokenProcessingTest(json, subData);
}

export async function InputButton(event, RequestID, func, from, props) {
  let ClickedButton = event.target.value,
    request;
  if (props && props.Button && props.Request) {
    ClickedButton = props.Button;
    request = props.Request;
  }
  let params = new Map(),
    data = { Result: ClickedButton },
    json;

  if (request) data = { Result: ClickedButton, [request.Name]: request.Value };
  if (request && request.subName) data[request.subName] = request.Value;

  params.set("prefix", "project");
  params.set("comand", "ResumeRequest");
  params.set("RequestID", RequestID);
  params.set("WSM", "1");
  json = await AxiosRequest(true, params, data);
  tokenProcessingTest(json, {
    func: func,
    propsId: RequestID,
    from: from,
    props: props,
  });
}

export function SetLoadingCursor(load, zIndex, remove) {
  if (load) {
    const Mask = document.getElementById("loadmask");
    if (Mask) {
      Mask.remove();
    } else if (!remove) {
      CreateBackgroundForModal(
        <div
          style={{
            cursor: "wait",
            width: "100%",
            height: "100%",
            userSelect: "none",
          }}
          loadmask=""
        ></div>,
        "loadmask",
      );
    }
  }
}

export function SetLoadingCursorAxios(load) {
  if (load) {
    return CreateBackgroundForModal(
      <div style={{ cursor: "wait", width: "100%", height: "100%" }} loadmask=""></div>,
      "loadmask" + CheckModalBackGrounds(),
    );
  }
}

const GetDialogParam = (ObjType, module, ObjRef, RequestID, OnlyView) => {
  let param = new Map(),
    json;
  param.set("prefix", module);
  param.set("comand", "GetDialogParams");
  param.set("ObjType", ObjType);
  param.set("ObjRef", ObjRef ? ObjRef : "0");
  if (OnlyView !== undefined) param.set("OnlyView", OnlyView);

  json = XMLrequest(param);
  return json;
};

function GetParams(json, param) {
  return json[param] === undefined ? json[param.toLowerCase()] : json[param];
}

function ChangeTextInModalButton(json) {
  const ObjWithNewText = {};
  for (const [key, value] of Object.entries(json)) {
    if (key.includes("Кнопка")) {
      Object.assign(ObjWithNewText, { [key.split("$")[1]]: value });
    }
  }
  return ObjWithNewText;
}

export function GetButtons(json) {
  let Buttons, ButtonsJson;
  if (typeof json === "number") {
    Buttons = json;
    ButtonsJson = {};
  } else {
    Buttons = json.Params?.Buttons;
    ButtonsJson = ChangeTextInModalButton(json.jsonData || {});
  }
  const buttonsArr = [];
  if (Buttons) {
    if (Buttons & 1) {
      buttonsArr.push(
        <Grid key={`modal-btn_1`} item>
          <Button
            size="small"
            style={{ textTransform: "none", marginLeft: 10 }}
            variant="outlined"
            className="button_Modal_Select"
            value={"1"}
          >
            {ButtonsJson["ОК"]
              ? ButtonsJson["ОК"]
              : ButtonsJson["Выбрать"]
              ? ButtonsJson["Выбрать"]
              : json.Token === "ExecuteModalDialog"
              ? "Выбрать"
              : "Ок"}
          </Button>
        </Grid>,
      );
    }

    if (Buttons & 4) {
      buttonsArr.push(
        <Grid key={`modal-btn_4`} item>
          <Button
            size="small"
            style={{ textTransform: "none", marginLeft: 10 }}
            variant="outlined"
            className="button_Modal_Select"
            value={"6"}
          >
            {ButtonsJson["Да"] ? ButtonsJson["Да"] : "Да"}
          </Button>
        </Grid>,
      );
    }
    if (Buttons & 8) {
      buttonsArr.push(
        <Grid key={`modal-btn_8`} item>
          <Button
            size="small"
            style={{ textTransform: "none", marginLeft: 10 }}
            variant="outlined"
            className="button_Modal_Select"
            value={"7"}
          >
            {ButtonsJson["Нет"] ? ButtonsJson["Нет"] : "Нет"}
          </Button>
        </Grid>,
      );
    }
    if (Buttons & 16) {
      buttonsArr.push(
        <Grid key={`modal-btn_16`} item>
          <Button
            size="small"
            style={{ textTransform: "none", marginLeft: 10 }}
            variant="outlined"
            className="button_Modal_Select"
            value={"3"}
          >
            {ButtonsJson["Прервать"] ? ButtonsJson["Прервать"] : "Прервать"}
          </Button>
        </Grid>,
      );
    }
    if (Buttons & 32) {
      buttonsArr.push(
        <Grid key={`modal-btn_32`} item>
          <Button
            size="small"
            style={{ textTransform: "none", marginLeft: 10 }}
            variant="outlined"
            className="button_Modal_Select"
            value={"4"}
          >
            {ButtonsJson["Повторить"] ? ButtonsJson["Повторить"] : "Повторить"}
          </Button>
        </Grid>,
      );
    }
    if (Buttons & 64) {
      buttonsArr.push(
        <Grid key={`modal-btn_64`} item>
          <Button
            size="small"
            style={{ textTransform: "none", marginLeft: 10 }}
            variant="outlined"
            className="button_Modal_Select"
            value={"5"}
          >
            {ButtonsJson["Пропустить"] ? ButtonsJson["Пропустить"] : "Пропустить"}
          </Button>
        </Grid>,
      );
    }
    if (Buttons & 128) {
      buttonsArr.push(
        <Grid key={`modal-btn_128`} item>
          <Button
            size="small"
            style={{ textTransform: "none", marginLeft: 10 }}
            variant="outlined"
            className="button_Modal_Select"
            value={"12"}
          >
            {ButtonsJson["Для всех"] ? ButtonsJson["Для всех"] : "Для всех"}
          </Button>
        </Grid>,
      );
    }
    if (Buttons & 256) {
      buttonsArr.push(
        <Grid key={`modal-btn_256`} item>
          <Button
            size="small"
            style={{ textTransform: "none", marginLeft: 10 }}
            variant="outlined"
            className="button_Modal_Select"
            value={"13"}
          >
            {ButtonsJson["Нет для всех"] ? ButtonsJson["Нет для всех"] : "Нет для всех"}
          </Button>
        </Grid>,
      );
    }
    if (Buttons & 512) {
      buttonsArr.push(
        <Grid key={`modal-btn_512`} item>
          <Button
            size="small"
            style={{ textTransform: "none", marginLeft: 10 }}
            variant="outlined"
            className="button_Modal_Select"
            value={"14"}
          >
            {ButtonsJson["Да для всех"] ? ButtonsJson["Да для всех"] : "Да для всех"}
          </Button>
        </Grid>,
      );
    }
    if (Buttons & 2) {
      buttonsArr.push(
        <Grid key={`modal-btn_2`} item>
          <Button
            size="small"
            style={{ textTransform: "none", marginLeft: 10 }}
            variant="outlined"
            className="button_Modal_Close"
            value={"2"}
          >
            {ButtonsJson["Отмена"] ? ButtonsJson["Отмена"] : "Отмена"}
          </Button>
        </Grid>,
      );
    }
  } else
    buttonsArr.push(
      <Grid key={`modal-btn_ok`} item>
        <Button
          size="small"
          style={{ textTransform: "none", marginLeft: 10 }}
          variant="outlined"
          className="button_Modal_Select"
          value={"1"}
        >
          ОК
        </Button>
      </Grid>,
    );
  return buttonsArr;
}

const activateRange = async (json, subData) => {
  const grids = Array.from(document.querySelectorAll("[salary_grid]"));
  const Sas = "";
  if (grids.length > 0) {
    const activateRangeGrids = grids.filter((val) => val.grid && val.grid.activateRange);
    for (const grid of activateRangeGrids) {
      grid.grid.activateRange({ year: json.Params.Year, month: json.Params.Month, range: json.Params.Range });
    }
  }
  await resumeRequest(json.RequestID, subData);
};

const refreshAmounts = async (reqId, subData) => {
  const grids = Array.from(document.querySelectorAll("[salary_grid]"));
  if (grids.length > 0) {
    await Promise.all(
      grids.map((grid) => grid.grid && grid.grid.refreshSource(grid.grid.source.getKeyFieldNameData())),
    );
  }
  await resumeRequest(reqId, subData);
};

var selestedFile;
export function tokenProcessingTest(json, subData = {}) {
  //  func, subData.propsId, from, props
  if (json !== undefined && json.Break !== undefined) {
    let returnSmth = [],
      Token,
      // Module,
      RequestID,
      from = subData.from,
      props = subData.props;
    // Module = json.Module;
    Token = json.Token;
    RequestID = json.Params ? json.Params.RequestID : undefined;
    window.removeEventListener("message", handleMessageList);
    window.removeEventListener("message", handleMessageSign);

    switch (Token) {
      case "RefreshAmounts": {
        refreshAmounts(RequestID, subData);
        break;
      }
      case "ActivateRange": {
        activateRange(json, subData);
        break;
      }
      case "SetClipboardText": {
        try {
          navigator.clipboard.writeText(json.Params.Text);
        } catch {
          alert(json.Params.Text);
        } finally {
          resumeRequest(RequestID, subData);
        }
        break;
      }
      case "GetClipboardText": {
        try {
          navigator.clipboard
            .readText()
            .then((clipText) => resumeRequest(RequestID, subData, { Result: clipText }))
            .catch(() => {
              resumeRequest(RequestID, subData);
            });
        } catch {
          resumeRequest(RequestID, subData);
        }
        break;
      }
      case "MessageBox":
        openModal(
          <MessageBox
            DlgType={json.Params.DlgType ? Number(json.Params.DlgType) : 0}
            from={from}
            Message={json.Params.Message}
            Buttons={json.Params.Buttons}
            RequestID={RequestID}
            onSelect={InputButton}
            setReturnValue={subData.func}
          />,
          {
            style: getStyleWindow("MessageBox"),
            hiddenButton: true,
            blockMaximize: true,
            blockSaveLocation: true,
            blockResize: true,
            Title: json.Params.DlgType
              ? TITLE_MESSAGE_MODAL_WINDOW[Number(json.Params.DlgType)]
              : TITLE_MESSAGE_MODAL_WINDOW[0],
            onClose: () => {
              InputButton({ target: { value: "2" } }, RequestID, subData.func, from);
            },
          },
        );
        break;

      case "HideProgressDialog":
        const progressDialog = document.getElementById("footerProgress");
        if (progressDialog && progressDialog.firstChild) progressDialog.firstChild.remove();
        const ProgressDialogRef = document.getElementById("ProgressDialog" + RequestID);
        if (ProgressDialogRef && ProgressDialogRef.hideDialog) {
          ProgressDialogRef.hideDialog();
        }
        resumeRequest(RequestID, subData);
        break;

      case "ChangeStatusProgress":
        ReactDOM.render(
          <ChangeStatusProgressFooter Json={json} setReturnValue={subData.func} from={from} subData={subData} />,
          document.getElementById("footerProgress"),
        );
        break;

      case "EditText":
        let Caption;
        // ,Title;
        Caption = json.Params.Caption;
        // Title = json.Params.Title;
        let WinTxt = (
          <WinForText
            Text={json.Params.Text}
            onSave={InputTextChange}
            RequestID={RequestID}
            Title={Caption}
            func={subData.func}
          />
        );
        returnSmth.push(
          /*  <Grid component={"form"} container direction="column" justifyContent="center" alignItems="flex-end" spacing={2}>
                          <Grid item>
                              <TextField id="input-text" name="input-text" label={Title} variant="outlined" fullWidth />
                          </Grid>
                          <Grid item>
                              <Button size="small" style={{textTransform:"none"}}  variant="outlined" value={1} onClick={(e) => InputTextChange(e, RequestID)} >Ок</Button>
                              <Button size="small" style={{textTransform:"none"}}  variant="outlined" value={2} onClick={(e) => InputTextChange(e, RequestID)}> Отмена</Button>
                          </Grid>
                      </Grid>*/
          WinTxt,
        );
        CreateBackgroundForModal(
          WinTxt,

          /* <ModalWindow
                         Title={Caption}
                         style={{ height: `${160}px`, width: `${300}px` }}
                         setReturnValue={subData.func}
                         hiddenButton={true}
                         headerButtons={[]}
                         resize={false}>
                         <Grid component={"form"} container direction="column" justifyContent="center" alignItems="center" spacing={2} style={{position:"absolute",left:"15px",top:"64px"}}>
                             <Grid item>
                                 <TextField id="input-text" name="input-text" label={Title} variant="outlined" fullWidth  />
                             </Grid>
                             <Grid item>
                                 <Button className="button_Modal_Select" size="small" style={{textTransform:"none"}}  variant="outlined" value={1} onClick={(e) => InputTextChange(e, RequestID)} >
                                     Ок
                                 </Button>
                                 <Button className="button_Modal_Select" size="small" style={{textTransform:"none", marginLeft:"10px"}}  variant="outlined" value={2} onClick={(e) => InputTextChange(e, RequestID)}>
                                     Отмена
                                     </Button>
                             </Grid>
                         </Grid>
                     </ModalWindow>   */
        );
        // ReactDOM.render(<ModalContainer dlgType={Caption} content={returnSmth} />, document.getElementById('footerProgress'));
        break;

      case "InputText":
        let inputTextEditor;
        if (json.Params.MultiLines === "1") {
          inputTextEditor = (
            <TextAreaWithButtons
              props={{
                Title: json.Params.Title,
                Value: json.Params.Text,
                maxLength: json.Params.Length,
                RequestID: RequestID,
                func: subData.func,
                onEdit: (data) => {
                  const json = resumeRequest(RequestID, subData, {
                    Text: data.value,
                    Result: "1",
                  });
                  if (json.NeedRefresh) data.func(json, data);
                },
              }}
            />
          );
        } else {
          inputTextEditor = (
            <DialogWithTextField
              props={{
                Title: json.Params.Title,
                Value: json.Params.Text,
                onSelect: (data) => {
                  const json = resumeRequest(RequestID, subData, {
                    Text: data.value,
                    Result: "1",
                  });
                  if (json.NeedRefresh) data.func(json, data);
                },
              }}
            />
          );
        }
        CreateBackgroundForModal(
          <ModalWindow
            Title={json.Params.Caption}
            style={getStyleWindow(json.Params.MultiLines === "1" ? undefined : "InputText")}
            hiddenButton={true}
            RequestID={RequestID}
            onClose={() => {
              resumeRequest(RequestID, subData, {});
            }}
            setReturnValue={subData.func}
            from={from}
          >
            {inputTextEditor}
          </ModalWindow>,
        );
        break;

      case "ShowProgressDialog":
        let Path = json.Params.Path;
        // todo ReactDOM.unmountComponentAtNode
        let doc = document.getElementById("RenderModal");
        ReactDOM.render(
          <ModalProgress open={true} Json={json} path={Path} setReturnValue={subData.func} from={from} />,
          doc,
        );
        break;

      case "SetProgressLabel":
        if (subData.from === "ModalProgress") {
          subData.func(json);
        } else {
          resumeRequest(RequestID, subData);
        }
        break;

      case "SelectFile":
        let fileFilter;
        fileFilter = json.Params.Filter;
        if (fileFilter !== undefined) {
          fileFilter = fileFilter.match(/\.[a-z]+/g);
          fileFilter = fileFilter.filter(function (item, pos) {
            return fileFilter.indexOf(item) == pos;
          });
          fileFilter.join(",");
        }
        buildFileSelector({ filter: fileFilter, multiple: Boolean(json.Params.MultiSel) }, RequestID, subData);
        break;

      case "SelectFolder":
        resumeRequest(RequestID, subData, { Result: "1", Path: "" });
        break;

      case "GetFileSize":
        let size = subData.size;
        if (subData.props && subData.props.files) {
          for (const file of Object.values(subData.props.files)) {
            if (file.name === json.Params.FileName) size = file.size;
          }
        }
        InputButton({ target: { value: size } }, RequestID, subData.func, subData.from, subData.props);
        break;

      case "SelectObject":
        if (json.Params) {
          let DialogParam = GetDialogParam(
            json.Params.ObjType,
            json.Params.Module,
            json.Params.ObjRef,
            json.Params.RequestID,
            json.Params.OnlyView,
          );

          CreateBackgroundForModal(
            <ModalWindow
              Title={json.Title ? json.Title : DialogParam.Title}
              style={getStyleWindow(
                json.CLSID
                  ? json.CLSID
                  : DialogParam.CLSID
                  ? DialogParam.CLSID
                  : "{7FAC2B20-109D-4406-A757-61674277B71F}",
              )}
              hiddenButton={true}
              // onSelect={InputButton}
              onClose={() => {
                resumeRequest(RequestID, subData, {});
              }}
              RequestID={json.Params ? json.Params.RequestID : undefined}
              setReturnValue={subData.func}
            >
              <HandleTable
                json={DialogParam}
                subData={{
                  props: {
                    SectionID: getModalSectionID(),
                    Buttons: json.Params.OnlyView === "1" ? 2 : undefined,
                    ...subData.props,
                    ...json.Params,
                    ...DialogParam,
                    disiableState: true,
                    func: subData.func,
                    from: subData.from,
                  },
                }}
              />
            </ModalWindow>,
          );
        }

        break;

      case "SelectDocument":
        let subJson = json.jsonData ? json.jsonData : {};
        if (json.Params) {
          CreateBackgroundForModal(
            <ModalWindow
              Title={"Выбор документа"}
              style={getStyleWindow("{03338F11-FC1E-11D4-A605-0000E8D9CBDB}")}
              hiddenButton={true}
              // onSelect={InputButton}
              headerButtons={["close", "maximize"]}
              onClose={() => {
                resumeRequest(RequestID, subData, {});
              }}
              RequestID={json.Params ? json.Params.RequestID : undefined}
              setReturnValue={subData.func}
            >
              <HandleTable
                json={{
                  ...json,
                  ...subJson,
                  CLSID: "{03338F11-FC1E-11D4-A605-0000E8D9CBDB}",
                }}
                subData={{
                  props: {
                    SectionID: getModalSectionID(),
                    Buttons: json.Params.OnlyView === "1" ? 2 : undefined,
                    ...json.Params,
                    ...subData.props,
                    func: subData.func,
                    from: subData.from,
                  },
                }}
              />
            </ModalWindow>,
          );
        }

        break;

      case "PriviewDocument":
        CreateBackgroundForModal(
          <ModalWindow
            Title={json.Params.Title}
            style={getStyleWindow("{408E20A3-4BE3-4DCD-98BD-2613A8968783}")}
            hiddenButton={true}
            // onSelect={InputButton}
            onClose={() => {
              resumeRequest(RequestID, subData, {});
            }}
            RequestID={RequestID}
            // setReturnValue={subData.func}
            headerButtons={["close", "maximize"]}
          >
            <HandleTable
              json={{
                ...json,
                CLSID: "{408E20A3-4BE3-4DCD-98BD-2613A8968783}",
              }}
              subData={{
                props: {
                  ...subData.props,
                  Module: json.Module,
                  SectionID: getModalSectionID(),
                  func: subData.func,
                  from: subData.from,
                  CLSID: "{408E20A3-4BE3-4DCD-98BD-2613A8968783}",
                  ViewIdent: json.Params ? json.Params.ViewIdent : "",
                  content: json.content,
                  Buttons: -1,
                  NoContextMenu: true,
                },
              }}
            />
          </ModalWindow>,
        );
        break;

      case "HandleTable":
        CreateBackgroundForModal(
          <ModalWindow
            Title={json.Title}
            style={getStyleWindow(json.CLSID ? json.CLSID : json.DlgType)}
            hiddenButton={true}
            // onSelect={InputButton}
            onClose={() => {
              subData?.props?.onClose?.();
              resumeRequest(RequestID, subData, {});
            }}
            RequestID={json.Params ? json.Params.RequestID : undefined}
            setReturnValue={subData.func}
          >
            <HandleTable
              json={json}
              subData={{
                props: {
                  ...subData.props,
                  SectionID: getModalSectionID(),
                  func: subData.func,
                  from: subData.from,
                },
              }}
            />
            {/* <DialogBook
              ObjType={json.Params.ObjType}
              CLSID={json.CLSID}
              RequestID={json.Params.RequestID}
              SectionID={subData.propsId}
              style={{ height: 300, width: 400 }}
              setReturnValue={subData.func}
              Module={Module}
              data={props.data}
            /> */}
          </ModalWindow>,
        );
        break;

      case "FindDuplicates":
        resumeRequest(RequestID, subData);
        break;

      case "StepProgress":
        if (subData.from === "ModalProgress") {
          subData.func(json);
        } else {
          resumeRequest(RequestID, subData);
        }
        break;

      case "AddressEditKladrObj":
        CreateBackgroundForModal(
          <ModalWindow
            Title={json.Params.Title}
            style={getStyleWindow()}
            hiddenButton={true}
            // onSelect={InputButton}
            onClose={() => {
              resumeRequest(RequestID, subData, {});
            }}
            RequestID={json.Params.RequestID}
            setReturnValue={subData.func}
          >
            <DialogKldr
              Module={json.Module}
              ObjType={json.Params.Level}
              Code={json.Params.Code}
              RecordIndex={
                json.Params.RecordIndex && Number(json.Params.RecordIndex) >= 0 ? json.Params.RecordIndex : "0"
              }
              RequestID={json.Params.RequestID}
              data={subData.props}
              SectionID={getModalSectionID()}
            ></DialogKldr>
          </ModalWindow>,
        );
        break;

      case "GetFileStream":
        let FileName = json.Params.FileName,
          data,
          RCDATAlocal = "",
          params = new Map();
        if ((selestedFile !== undefined && FileName === selestedFile.name) || (subData.props && subData.props.files)) {
          if (selestedFile && FileName === selestedFile.name) RCDATAlocal = selestedFile.RCDATA;
          if (subData.props && subData.props.files) {
            for (const [index, file] of Object.entries(subData.props.files)) {
              if (index === FileName) RCDATAlocal = file.rcdata;
            }
          }
          params.set("prefix", "project");
          params.set("comand", "ResumeRequest");
          params.set("RequestID", RequestID);
          params.set("WSM", "1");
          data = { RCDATA: RCDATAlocal };
          AxiosRequest(true, params, data).then((res) => {
            tokenProcessingTest(res, subData);
          });
        }
        break;

      case "FileExists":
        resumeRequest(RequestID, subData, { Result: "1" });
        break;

      case "ExecuteParamDialog":
        SetLoadingCursor(true, undefined, true);
        let params1 = new Map(),
          json1;
        params1.set("prefix", "programs");
        params1.set("comand", "GetParamDialog");
        params1.set("GroupID", "0"); ///// ПРОВЕРИТЬ
        params1.set("Path", json.Params.Path);
        params1.set("NeedRefresh", "1");
        json1 = XMLrequest(params1);

        openModal(
          <DialogParams
            props={{
              Module: "programs",
              reqCommand: "GetParamDialog",
              GroupID: "0",
              Path: json.Params.Path,
              RequestID: json.Params.RequestID,
              setReturnValue: subData.func,
              from: from,
              func: subData.func,
              data: json1,
              NoTitle: !json1.Title,
              NoHistory: true,
              ExecEvent: json?.Params?.ExecEvent,
              onSelect: InputButton,
              onClose: () => resumeRequest(RequestID, subData, {}),
            }}
          />,
          {
            Title: json1.Caption,
            style: getStyleWindow("params"),
            hiddenButton: true,
            onClose: () => resumeRequest(RequestID, subData, {}),
          },
        );
        break;

      case "ExecuteModalDialog":
        if (json.Params.CLSID === "{C0CED968-8834-405D-8801-A3838BF536F3}") {
          let height, width, Path, setFormData;

          function setForm(data) {
            setFormData = data;
          }

          function GetFormFunction() {
            return setFormData;
          }

          let JSX = <FormsMainFile id={subData.propsId} jsonFormData={json.jsonData} setForm={setForm} />;
          height = (parseInt(GetParams(json.jsonData.Form, "Height")) + 111).toString();
          width = (parseInt(GetParams(json.jsonData.Form, "Width")) * 1.05).toString();
          if (json.jsonData.Form.Height === undefined) {
            JSX = (
              <FormsMainFile
                id={subData.propsId}
                SubDataProcessing={true}
                jsonFormData={json.jsonData}
                setForm={setForm}
              />
            );
            height = GetParams(json.jsonData, "Height");
            width = GetParams(json.jsonData, "Width");
          }
          height = Number(height) > window.innerHeight ? window.innerHeight : height;
          Path = json.jsonData.Form.Path;
          CreateBackgroundForModal(
            <ModalWindow
              Path={Path}
              Title={json.Params.Caption}
              style={{ height: `${height}px`, width: `${width}px` }}
              onSelect={InputButton}
              onClose={InputButton}
              setReturnValue={subData.func}
              from={from}
              RequestID={json.Params.RequestID}
              Buttons={json}
              setForm={GetFormFunction}
            >
              {JSX}
            </ModalWindow>,
          );
          //let newModalDialog = document.createElement("dialog");
          // ReactDOM.render(<DialogSlide content={JSX} caption={json.Params.Caption} RequestID={json.Params.RequestID} style={{height: `${height}px`, width: `${width}px`}} Path={Path} /> , newModalDialog);
          // document.getElementById('RenderFormsModal').append(newModalDialog)
        } else {
          let excProps = {};
          if (json.jsonData) excProps = { ...excProps, ...json.jsonData };
          if (json.Params) excProps = { ...excProps, ...json.Params };
          CreateBackgroundForModal(
            <ModalWindow
              Title={json.Params.Caption}
              style={getStyleWindow(json.Params.CLSID)}
              hiddenButton={true}
              // onSelect={InputButton}
              onClose={InputButton}
              from={from}
              setReturnValue={subData.func}
              RequestID={json.Params.RequestID}
            >
              <HandleTable
                json={excProps}
                subData={{
                  props: {
                    ...excProps,
                    SectionID: getModalSectionID(),
                    func: subData.func,
                    from: subData.from,
                    // htmlInput: json.jsonData.content,
                    // caption: json.Params.Caption,
                    // RequestID: json.Params.RequestID,
                  },
                }}
              />
            </ModalWindow>,
          );
        }
        break;

      case "ExposeDocument":
        CreateBackgroundForModal(
          <ModalWindow
            Title={"Документ"}
            style={getStyleWindow("{48C155C0-1336-11D6-9CF2-DB1D5BC3046D}")}
            hiddenButton={true}
            // onSelect={InputButton}
            onClose={() => {
              resumeRequest(RequestID, subData, {});
            }}
            headerButtons={["close", "maximize"]}
            from={from}
            setReturnValue={subData.func}
            RequestID={json.Params.RequestID}
          >
            <HandleTable
              json={{
                ...json.Params,
                CLSID: "{48C155C0-1336-11D6-9CF2-DB1D5BC3046D}",
              }}
              subData={{
                props: {
                  ...json.Params,
                  Module: json.Module,
                  Buttons: 2,
                  SectionID: getModalSectionID(),
                  PlaneView: "1",
                  detailsOpen: true,
                  func: subData.func,
                  from: subData.from,
                  // htmlInput: json.jsonData.content,
                  // caption: json.Params.Caption,
                  // RequestID: json.Params.RequestID,
                },
              }}
            />
          </ModalWindow>,
        );
        break;
      case "EditDocumentField":
        let DialogParam = GetDialogParam(subData.props.ObjType, subData.props.Module, subData.props.ObjRef, RequestID);

        CreateBackgroundForModal(
          <ModalWindow
            Title={DialogParam.Title}
            style={getStyleWindow(DialogParam.CLSID)}
            hiddenButton={true}
            // onSelect={InputButton}
            onClose={() => {
              resumeRequest(RequestID, subData, {});
            }}
            from={from}
            setReturnValue={subData.func}
            RequestID={RequestID}
          >
            <HandleTable
              json={DialogParam}
              subData={{
                props: {
                  ...subData.props,
                  RequestID: RequestID,
                  SectionID: getModalSectionID(),
                  func: subData.func,
                  from: subData.from,
                  // htmlInput: json.jsonData.content,
                  // caption: json.Params.Caption,
                  // RequestID: json.Params.RequestID,
                },
              }}
            />
          </ModalWindow>,
        );
        break;

      case "ShellExecute":
        let RCDATA = "";
        let FileNameShell = json.Params.FileName;
        FileNameShell = FileNameShell.split("\\");
        if (json.RCDATA) {
          RCDATA = json.RCDATA;
        } else if (subData.RCDATA) {
          RCDATA = subData.RCDATA;
        } else {
          window.open(json.Params.FileName);
          resumeRequest(RequestID, subData);
          return;
        }
        let mimeType = mime.lookup(FileNameShell.slice(-1)[0]);
        RCDATA = "data:" + mimeType + ";base64," + RCDATA;
        if (showContent(json, RCDATA, mimeType, subData, FileNameShell)) {
          downloadFile(RCDATA, json, subData);
        }
        break;

      case "SelectListIndex":
        if (!json || !json.Params) break;
        const items = json.Params.Items.replaceAll(`"`, "")
          .split(",")
          .map((item, index) => {
            return { text: item, id: index };
          });
        openModal(
          <DialogSetObjText
            list={items}
            RequestID={json.Params.RequestID}
            onClose={() => InputButton({ target: { value: "2" } })}
          />,
          {
            Title: json.Params.Caption,
            style: getStyleWindow(),
            hiddenButton: true,
            blockMaximize: true,
            setReturnValue: subData.func,
            from: from,
            onClose: () => InputButton({ target: { value: "2" } }),
          },
        );
        // ReactDOM.render(
        //   <ModalSelectListIndex Json={json} />,
        //   document.getElementById("RenderModal")
        // );

        break;

      case "GetDirectory":
        resumeRequest(RequestID, subData);
        break;

      case "OutPutText":
        outPutShow(json.Params.Text ?? "");
        // OutPutInput.value += json.Params.Text + "\n";
        resumeRequest(RequestID, subData);
        break;

      case "ClearOutPut": {
        outPutShow();
        resumeRequest(RequestID, subData);
        break;
      }

      case "PutFileStream":
        resumeRequest(RequestID, { ...subData, RCDATA: json.RCDATA });
        break;

      case "GetCertificateList":
        // if (!CheckWorkSpace()) return;
        // window.addEventListener("message", handleMessageList, false);
        // (subData.propsId)
        funcGlobal = subData.func;
        executeCertList(RequestID)
          .then((value) => {
            handleMessageList(value, subData, RequestID);
          })
          .catch((error) => {
            // console.log(error);
            if (
              !CheckWorkSpace(() => {
                resumeRequest(RequestID);
              })
            )
              return;
            RenderMessageBox(1, 1, error, InputButton, funcGlobal);
          });
        break;

      case "ClientSignStream":
        // if (!CheckWorkSpace()) return;
        // window.addEventListener("message", handleMessageSign, false);
        // (subData.propsId)
        funcGlobal = subData.func;
        executeDetachedTest(json.Params.Serial, json.RCDATA)
          .then((value) => {
            handleMessageSign(value, RequestID, subData);
          })
          .catch((error) => {
            if (
              !CheckWorkSpace(() => {
                resumeRequest(RequestID);
              })
            )
              return;
            RenderMessageBox(1, 1, error, InputButton, funcGlobal);
          });
        break;

      case "SignDialog":
        CreateBackgroundForModal(
          <ModalWindow
            Title={"Подпись"}
            resize={false}
            style={getStyleWindow()}
            hiddenButton={true}
            // onSelect={InputButton}
            onClose={InputButton}
            setReturnValue={subData.func}
            from={from}
            RequestID={json.Params.RequestID}
          >
            <SignDialog content={json.content} {...json.Params} subData={subData} />
          </ModalWindow>,
        );
        break;

      case "ClientValidateStream":
        webkcrypt_invoke(
          "webkcrypt-pkcs7-verify",
          {
            pkcs7: {
              value: json.RCDATA,
              "image-length": json.Params.Size ? Number(json.Params.Size) : 0,
            },
          },
          (eventVerify) => {
            if (eventVerify.data.invoke.out.failed === 0)
              //todos Если ошибка то отобразить messagebox  eventVerify.data.invoke.out.value.failure
              resumeRequest(json.Params.RequestID, subData, {
                Info: eventVerify.data.invoke.out.value.hint,
              });
            //
            // webkcrypt_invoke("webkcrypt-pkcs7-explode", {pkcs7:{value:json.RCDATA, "image-length": json.Params.Size?Number(json.Params.Size):0}}, (event) => {
            //   const data = event.data.invoke.out;
            //
            //   if (data.failed != 0) {
            //     alert("Ошибка: " + data.value.failure);
            //   }
            //   else {
            //
            //     data.value.certificates.forEach((cert)=>{
            //       webkcrypt_invoke("webkcrypt-x509-explode", {x509:cert}, (ev) => {
            //
            //
            //       })
            //     })
            //     // alert("Успех: " + data.value.hint);
            //   }
            // })
          },
        );

        //
        // EmptyRequest(json.Params.RequestID, subData.func);
        break;

      case "ShowDataSet":
        switch (json.Params.DlgType) {
          case "1": //Грид
            CreateBackgroundForModal(
              <ModalWindow
                Title={json.Params.Title}
                style={getStyleWindow("ShowDataSet")}
                hiddenButton={true}
                // onSelect={InputButton}
                onClose={() => {
                  resumeRequest(RequestID, subData, {});
                }}
                from={from}
                setReturnValue={subData.func}
                RequestID={json.Params.RequestID}
              >
                <DialogCert
                  Module={"cert"}
                  Path={json.Params.Path}
                  RecordIndex={json.Params.RecordIndex}
                  RequestID={json.Params.RequestID}
                  setReturnValue={subData.func}
                  style={{ height: 300, width: 500 }}
                  SectionID={getModalSectionID()}
                  from={subData.from}
                />
              </ModalWindow>,
            );
            break;

          case "2": //Дерево
            function onSelectTree(tree, data, selection) {
              if (tree) {
                let item = tree.querySelector(".tree-item-selected");
                if (item) {
                  let id = item.id;
                  let text = item.querySelector(`#text-tree-item_${id}`).innerText;

                  const res = { Result: id };
                  if (data.MultiSel) {
                    res.Selection = selection;
                  }

                  let params = new Map();
                  params.set("prefix", "project");
                  params.set("comand", "ResumeRequest");
                  params.set("RequestID", json.Params.RequestID);
                  params.set("WSM", "1");
                  let otv = XMLrequest(params, res);
                  tokenProcessingTest(otv, {
                    func: data.func,
                    from: data.from,
                  });
                  // params = new Map();
                  // params
                  //   .set("prefix", "forms")
                  //   .set("comand", "ElementEvent")
                  //   .set("SectionID", store.getState().sideBar.selected.ID)
                  //   .set("Name", subData.Name)
                  //   .set("Tag", id)
                  //   .set("Text", item.innerText)
                  //   .set("WSM", "1");
                  // XMLrequest(params);
                  if (data.input) data.input.dataset.objref = id;
                  if (data.setValue) data.setValue({ label: text, id: id });
                  if (data.setInputValue) data.setInputValue(text);
                }
              }
            }
            CreateBackgroundForModal(
              <ModalWindow
                Title={json.Params.Title}
                style={getStyleWindow("ShowDataSet")}
                hiddenButton={true}
                // onSelect={InputButton}
                onClose={() => {
                  resumeRequest(RequestID, subData, {});
                }}
                setReturnValue={subData.func}
                from={from}
                RequestID={json.Params.RequestID}
              >
                <TestTree
                  props={{
                    ...subData.props,
                    RequestID: RequestID,
                    CLSID: json.CLSID,
                    Module: json.Module,
                    SectionID: getModalSectionID(),
                    ...json.Params,
                    MultiSel: json.Params.MultiSel ? json.Params.MultiSel : json.Params.CanMultiSel,
                    ObjRef: json.Params.ObjRef ? json.Params.ObjRef : "0",
                    Current: json.Params.ObjRef ? json.Params.ObjRef : "0",
                    UsedDate: "0",
                    Info: "0",
                    Internal: "0",
                    showButtons: true,
                    setInputValue: subData.setInputValue,
                    setValue: subData.setValue,
                    onSelect: onSelectTree,
                    from: subData.from,
                    func: subData.func,
                  }}
                />
              </ModalWindow>,
            );
            break;
          default:
            // EmptyRequest(json.Params.RequestID);

            CreateBackgroundForModal(
              <ModalWindow
                Title={json.Params.Title}
                style={getStyleWindow()}
                hiddenButton={true}
                from={from}
                setReturnValue={subData.func}
                // onSelect={InputButton}
                onClose={() => {
                  resumeRequest(RequestID, subData, {});
                }}
              >
                <DialogBook
                  props={{
                    ...subData.props,
                    from: subData.from,
                    func: subData.func,
                    Module: json.Module,
                    Path: json.Params.Path,
                    RequestID: json.Params.RequestID,
                    ObjRef: json.Params.RecordIndex,
                    selectKey: "RecordIndex",
                    SectionID: getModalSectionID(),
                  }}
                />
              </ModalWindow>,
            );
            break;
        }
        break;

      case "Navigate":
        if (
          json.Params.PageID === "-2" &&
          store.getState().sideBar.data[store.getState().sideBar.data.length - 1].ID !== "-2"
        ) {
          store.dispatch(
            pushSideBar({
              CLSID: "{D22DFDA0-DB27-4FB4-865F-B478DB85F2A4}",
              ID: "-2",
              Name: "Фоновые процессы",
            }),
          );
        }
        store.dispatch(setSelectedSlice(GetJsonAttributeByID(json.Params.PageID, store.getState().sideBar.data)));

        resumeRequest(RequestID, subData);
        break;

      case "ClearFormOnSection":
        updateSection(json.Params.SectionID, "");

        // setTimeout(() => {
        resumeRequest(RequestID, subData);
        // }, 1250);
        break;

      case "CommitFormOnSection":
        updateSection(json.Params.SectionID, json.Params.Section);
        setTimeout(() => {
          resumeRequest(RequestID, subData);
        }, 1250);
        break;

      case "RefreshDocument": {
        const documentByDcfg = document.getElementById("gridpanel" + json.Params.DocCfgID);
        if (documentByDcfg) {
          documentByDcfg.grid.refreshFullDocumnet &&
            documentByDcfg.grid.refreshFullDocumnet().then(() => {
              resumeRequest(RequestID, subData);
            });
        } else {
          resumeRequest(RequestID, subData);
        }
        break;
      }
      default:
        resumeRequest(RequestID, subData);
    }
  } else {
    let docs = document.getElementById("footerProgress");
    docs.innerHTML = "";
    ReactDOM.render(<></>, document.getElementById("footerProgress"));
    //
    const Mask = document.getElementById("loadmask");
    if (Mask) {
      Mask.remove();
    }
    if (!isEmptyObject(json)) {
      if (!isEmptyObject(json.Form) || json.ViewIdent !== undefined) {
        subData.func(json);
      } else if (!isEmptyObject(json)) {
        if (subData.func) {
          subData.func(json);
          // return ReturnData
        }
      }
      // if (json.Result) subData.func(json.Result);
    } else if (subData.from === "grid" && subData.func) {
      subData.func(json);
    }
    // }
  }
}

function showContent(data, RCDATA, mimeType, subData) {
  if (mimeType === false) return true;
  if (mimeType.includes("png") || mimeType.includes("jpg") || mimeType.includes("jpeg")) {
    const myImage = new Image();
    try {
      myImage.onload = function () {
        const orientationVertical = myImage.naturalHeight > myImage.naturalWidth;
        openModal(
          <div style={{ display: "inline-flex", width: "100%", height: "100%" }}>
            <img src={RCDATA}></img>
          </div>,
          {
            Title: "Предосмотр картинки",
            Buttons: {
              jsonData: {
                Кнопка$Выбрать: "Скачать",
                Кнопка$Отмена: "Закрыть",
              },
              Params: { Buttons: 3 },
            },
            style: {
              width: `${orientationVertical ? window.innerHeight * 0.3 : window.innerHeight * 0.75}px`,
              height: `${window.innerHeight * 0.75}px`,
            },
            blockMaximize: true,
            onSelect: () => {
              downloadFile(RCDATA, data, subData);
              resumeRequest(data.Params.RequestID, subData);
            },
            onClose: () => {
              resumeRequest(data.Params.RequestID, subData);
            },
          },
        );
      };
      myImage.src = RCDATA;
      return false;
    } catch {
      downloadFile(RCDATA, data, subData);
      resumeRequest(data.Params.RequestID, subData);
    }
  } else {
    return true;
  }
}

function downloadFile(RCDATA, json, subData) {
  let FileNameShell = json.Params.FileName;
  FileNameShell = FileNameShell.split("\\");
  let a = document.createElement("a");
  a.href = RCDATA;
  a.download = FileNameShell.slice(-1)[0];
  a.click();
  resumeRequest(json.Params.RequestID, subData);
}
