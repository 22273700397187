import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { formEdit } from "../../Tools/Tools";
import { closeModal } from "../../Tools/modalManager";
import styles from "./Components/styles.module.css";
import DayTypeTitle from "./Components/dayTypeComponents/dayTypeTitle";
import DayTypeCheckBox from "./Components/dayTypeComponents/DayTypeCheckBox";
import cn from "classnames";

const defaultCheckState = {
  Отработанные: 0,
  Неотработанные: 0,
  Обычный: 0,
  Праздничный: 0,
  Выходной: 0,
  ПредПраздничный: 0,
  Понедельник: 0,
  Вторник: 0,
  Среда: 0,
  Четверг: 0,
  Пятница: 0,
  Суббота: 0,
  Воскресенье: 0,
};

const daysData = {
  0: "Понедельник",
  1: "Вторник",
  2: "Среда",
  3: "Четверг",
  4: "Пятница",
  5: "Суббота",
  6: "Воскресенье",
};
const typesData = {
  0: "Обычный",
  1: "Праздничный",
  2: "Выходной",
  3: "ПредПраздничный",
};
const statesData = {
  0: "Отработанные",
  1: "Неотработанные",
};

export default function DialogDayType({ props }) {
  const [checkValues, setChecValues] = useState(defaultCheckState);

  useEffect(() => {
    setChecValues(getCheckStates(props.ObjRef));
  }, []);

  const getCheckStates = (objref) => {
    const result = {};
    const days = (objref >> 16) & 0b1111111;
    const types = (objref >> 24) & 0b1111;
    const states = (objref >> 28) & 0b11;

    for (let i = 0; i < 7; i++) {
      result[daysData[i]] = (days >> i) & 0b1;
    }
    for (let i = 0; i < 4; i++) {
      result[typesData[i]] = (types >> i) & 0b1;
    }
    for (let i = 0; i < 2; i++) {
      result[statesData[i]] = (states >> i) & 0b1;
    }
    return result;
  };

  const getTag = () => {
    let result = 0;

    for (let i = 0; i < 7; i++) {
      result += checkValues[daysData[i]] << (i + 16);
    }
    for (let i = 0; i < 4; i++) {
      result += checkValues[typesData[i]] << (i + 24);
    }
    for (let i = 0; i < 2; i++) {
      result += checkValues[statesData[i]] << (i + 28);
    }
    return result;
  };

  async function onSelect() {
    if (props.onEdit && props.setValue && props.setInputValue) {
      props.onEdit({
        value: "",
        tag: getTag(),
        name: props.Name,
        record: props.record,
        textchanged: "0",
        setValue: props.setValue,
        setInputValue: props.setInputValue,
        type: props.Module,
        requestId: props.RequestID,
        addInfo: props.addInfo,
        docID: true,
      });
      closeModal();
      return;
    }

    if (props.RequestID) {
      await formEdit("1", props.func, { Name: "ObjRef", Value: getTag() }, props.RequestID, props.from);
    }
    closeModal();
  }

  const onEditCheckBox = (data) => {
    checkValues[data.name] = Number(data.CheckState);
    setChecValues({ ...checkValues });
  };

  return (
    <div className={styles.mainDialogBlock}>
      <div className={`${styles.fullDialogFlexContainer} ${styles.dialogDayTypeContainer}`}>
        <div className={styles.dialogDayTypeItemContainer}>
          <div className={styles.dialogDayTypeStateContainer}>
            <DayTypeTitle label="Виды дней" />
            <DayTypeCheckBox label="Отработанные" checkValues={checkValues} onEditCheckBox={onEditCheckBox} />
            <DayTypeCheckBox label="Неотработанные" checkValues={checkValues} onEditCheckBox={onEditCheckBox} />
          </div>
          <div className={styles.dialogDayTypeTypesContainer}>
            <DayTypeTitle label="Типы дней" />

            <DayTypeCheckBox label="Обычный" checkValues={checkValues} onEditCheckBox={onEditCheckBox} />
            <DayTypeCheckBox label="Праздничный" checkValues={checkValues} onEditCheckBox={onEditCheckBox} />
            <DayTypeCheckBox label="Выходной" checkValues={checkValues} onEditCheckBox={onEditCheckBox} />
            <DayTypeCheckBox label="ПредПраздничный" checkValues={checkValues} onEditCheckBox={onEditCheckBox} />
          </div>
        </div>
        <div className={styles.dialogDayTypeItemContainer}>
          <div className={styles.dialogDayTypeDaysContainer}>
            <DayTypeTitle label="Дни недели" />
            <DayTypeCheckBox label="Понедельник" checkValues={checkValues} onEditCheckBox={onEditCheckBox} />
            <DayTypeCheckBox label="Вторник" checkValues={checkValues} onEditCheckBox={onEditCheckBox} />
            <DayTypeCheckBox label="Среда" checkValues={checkValues} onEditCheckBox={onEditCheckBox} />
            <DayTypeCheckBox label="Четверг" checkValues={checkValues} onEditCheckBox={onEditCheckBox} />
            <DayTypeCheckBox label="Пятница" checkValues={checkValues} onEditCheckBox={onEditCheckBox} />
            <DayTypeCheckBox label="Суббота" checkValues={checkValues} onEditCheckBox={onEditCheckBox} />
            <DayTypeCheckBox label="Воскресенье" checkValues={checkValues} onEditCheckBox={onEditCheckBox} />
          </div>
        </div>
      </div>

      <div className={styles.dialogButtonsContainer}>
        <Button
          onClick={onSelect}
          size="small"
          variant="outlined"
          className={`${styles.dialogButton} button_Modal_Select ${cn(
            props.Buttons && (props.Buttons & 1) === 0 && "hidden",
          )}`}
          value="1"
        >
          Выбрать
        </Button>

        <Button
          size="small"
          variant="outlined"
          value="2"
          className={`${styles.dialogButton} button_Modal_Close`}
          onClick={() => {
            closeModal();
          }}
        >
          Закрыть
        </Button>
      </div>
    </div>
  );
}
