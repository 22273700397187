import React from "react";
import { GridLoader } from "../../../../rcsgrid/createGridTools";
import { handleTableAccounts } from "../../../../Tools/Requests";

export default function AcoountsTableForTabs({ props }) {
  const [mainGrid, setMainGrid] = React.useState();
  const gridPanel = React.useRef();
  const accBox = React.useRef();

  React.useEffect(() => {
    GridLoader(gridPanel.current, props, {
      sub: true,
      GroupID: props.selectedRecordID,
      ObjType: props.detailItem.ObjType ? props.detailItem.ObjType : props.detailItem.Params.ObjType,
      setSelectedRecord: props.setAccSelectedRecordID,
    }).then((res) => {
      setMainGrid(res);
    });
    props.subGridPanelRef.current = gridPanel.current;
  }, []);

  React.useEffect(() => {
    if (mainGrid) {
      accBox.current.refreshMainGrid = refreshMainGrid;
    }
  }, [mainGrid]);

  async function refreshMainGrid(data) {
    mainGrid.source.onHandleRequest = handleTableAccounts(props.Module, data.ObjType, data.GroupID, props.SectionID);

    await mainGrid.source.first();
    await mainGrid.source.refresh();
    await mainGrid.grid.setSource(mainGrid.source);
    props.setAccSelectedRecordID(mainGrid.source.getFieldTextSync("ID"));
    await mainGrid.grid.updateGridSize();
  }

  return (
    <div ref={accBox} id="accBox" style={{ height: "100%" }}>
      <div
        id={`rcgrid_panel`}
        ref={gridPanel}
        style={{
          height: "100%",
          width: "100%",
          position: "relative",
        }}
      ></div>
    </div>
  );
}
