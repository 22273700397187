import CheckBoxMultiCheck from "../../../../Sections/ElementsSections/CheckBoxMultiCheck";
import styles from "../styles.module.css";

export default function DayTypeCheckBox({ label, checkValues, onEditCheckBox }) {
  return (
    <div className={styles.dialogDayTypeCheckBoxContainer}>
      <div>
        <CheckBoxMultiCheck onEdit={onEditCheckBox} parentProps={{ name: label }} CheckState={checkValues[label]} />
      </div>
      <div className={styles.dialogDayTypelabel}>{label}</div>
    </div>
  );
}
