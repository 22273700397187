import { orgGetImage } from "../../../Module/Dialogs/DialogOrganisations";
import { handleTableAccounts, postHandleTableAccounts } from "../../../Tools/Requests";
import { getRightsData } from "../../../Tools/Tools";
import { parseColumns } from "../../GridTools/parseColumnTools";

export async function accountsGrid(grid, source, props, data) {
  let json = await postHandleTableAccounts(props.Module, data.ObjType, props.SectionID);
  source.onHandleRequest = handleTableAccounts(props.Module, data.ObjType, data.GroupID, props.SectionID);

  if (data.setSelectedRecord) {
    source.onRecordIndexChanged = function (source) {
      data.setSelectedRecord(source.getFieldTextSync("ID"));
    };
  }

  grid.setImageFields(["CloseDate", "ExternalKey"]);
  source.orgGetImage = orgGetImage;

  await source.open();

  if (json) {
    source.rights = getRightsData(json.Rights);
    grid.setColumns(parseColumns(json));
  } else grid.defaultColumns = true;

  await source.refresh();
  await grid.setSource(source);

  data.setSelectedRecord && data.setSelectedRecord(await source.getFieldText("ID"));

  await grid.updateGridSize();
}
