import { DocColumnOption } from "../../Tools/Consts";
import { ParamSetOption } from "../../Tools/ProjectX";
import { isEmptyObject } from "../../Tools/Tools";

interface Field {
  Title?: string;
  Name?: string;
  Width?: string;
  Level?: string;
  ID?: string;
  DataType?: string;
  EditStyle?: string;
  Options?: string;
  TitleAjust?: string;
  TextAjust?: string;
  HardCodeReadOnlyForEditor?: string;
  Module?: string;
  Values?: any[];
  Details?: string;
  AccDetails?: boolean;
  OnEdit?: string;
  OnList?: string;
  OnHint?: string;
  EditMask?: string;
  ColumnID?: string;
  OnCloseUpList?: string;
  OnGetEditStyle?: string;
  FieldName?: string;
  Path?: string;
  FilterReq?: string;
  ButtonImage?: string;
  MultiCheckSet?: string;
  ListCommand?: string;
  DetailsByReq?: string;
  OnListCustom?: string;
  OnDropDown?: string;
}

interface JsonTableLayout {
  readonly?: string;
  Columns?: Array<Field>;
}

interface AddInfo {
  Path?: string;
  Module?: string;
}

interface ListValue {
  text: string;
  id: string;
}

//Парсинг полей объекта запросов GetTableLayout и GetDocumentLayout
export function parseColumns(
  json: JsonTableLayout,
  inputColumns = { title: [] },
  collapsibleField: string,
  addInfo: AddInfo,
) {
  if (!json.Columns || isEmptyObject(json.Columns) || json.Columns.State) {
    if (!inputColumns || inputColumns.title.length === 0) {
      return {
        title: [],
      };
    }

    return {
      title: [...inputColumns?.title],
    };
  }

  const jsonColumns = json.Columns.map((value: Field) => {
    if (value.FieldName) {
      return {
        fieldName: value.FieldName,
        title: value.Title || value.Name, // у объекта GetDocumentLayout название поля приходит как Title, а у объекта GetTableLayout как Name
        width: (value.Width && parseInt(value.Width)) || 100,
        level: (value.Level && parseInt(value.Level)) || 0,
        fieldInfo: {
          DataType: value.DataType || "",
          EditStyle: value.EditStyle || "",
          Options: value.Options || "",
          TitleAjust: value.TitleAjust || "",
          TextAjust: value.TextAjust || "",
          HardCodeReadOnlyForEditor: json.readonly || "",
          Module: value.Module || addInfo?.Module || "",
          Values: (value.Values && getValues(Object.values(value.Values), Boolean(Number(value.EditStyle) & 32))) || [],
          Details: value.Details || "",
          AccDetails:
            value.DataType === "-1" && (value.FieldName === "D_Analitika" || value.FieldName === "C_Analitika"),
          OnEdit: value.OnEdit || "",
          OnList: value.OnList || "",
          OnHint: value.OnHint || "",
          EditMask: value.EditMask || "",
          ColumnID: value.ID || "",
          OnCloseUpList: value.OnCloseUpList || "",
          OnGetEditStyle: value.OnGetEditStyle || "",
          FieldName: value.FieldName || "",
          Path: addInfo?.Path || "",
          ButtonImage: value.ButtonImage || "",
          MultiCheckSet: value.MultiCheckSet || "",
          ListCommand: value.ListCommand || "",
          DetailsByReq: value.DetailsByReq || "",
          OnListCustom: value.OnListCustom || "",
          OnDropDown: value.OnDropDown || "",
          collapseRecord: value.FieldName === collapsibleField,
        },
      };
    }
  });

  if (inputColumns?.title.length === 0) {
    return {
      title: [...jsonColumns],
    };
  }

  return {
    title: [...inputColumns.title, ...jsonColumns],
  };
}

export function getValues(arr: Array<ListValue | string>, checklist: boolean) {
  return arr.map((item, index) => {
    if (typeof item !== "object") {
      if (checklist) {
        return { label: item, id: `${Math.pow(2, index)}` };
      }
      return { label: item, id: `${index}` };
    }
    if (checklist) {
      return { label: item.text, id: `${Math.pow(2, index)}` };
    }
    return { label: item.text, id: item.id };
  });
}
/*
  Markable: 1,
  Sortable: 2,
  ReadOnly: 4,
  NotEditing: 8,
  VerticalText: 16,
  ShortObjName: 32,
  Fixed: 64,
  RedNegative: 128,
  HiddenInGrid: 256,
  HiddenInInsp: 512,
  Filterable: 1024,
  ReferencLinke: 2048,
  InParams: 4096,
  CanGroup: 8192,
  Invisible: 16384,
  Hidden: 32768,
*/

export function getDocOptions(options: number | string) {
  return {
    markable: Boolean(Number(options) & DocColumnOption.Markable),
    sort: Boolean(Number(options) & DocColumnOption.Sortable),
    readOnly: Boolean(Number(options) & DocColumnOption.ReadOnly),
    disabled: Boolean(Number(options) & DocColumnOption.NotEditing),
    verticalText: Boolean(Number(options) & DocColumnOption.VerticalText),
    shortObjName: Boolean(Number(options) & DocColumnOption.ShortObjName),
    fixed: Boolean(Number(options) & DocColumnOption.Fixed),
    redNegative: Boolean(Number(options) & DocColumnOption.RedNegative),
    hideInGrid: Boolean(Number(options) & DocColumnOption.HiddenInGrid),
    hideInCard: Boolean(Number(options) & DocColumnOption.HiddenInInsp),
    filterByField: Boolean(Number(options) & DocColumnOption.Filterable),
    link: Boolean(Number(options) & DocColumnOption.Reference),
    inParams: Boolean(Number(options) & DocColumnOption.InParams),
    group: Boolean(Number(options) & DocColumnOption.CanGroup),
    invisible: Boolean(Number(options) & DocColumnOption.Invisible),
    hidden: Boolean(Number(options) & DocColumnOption.Hidden),
  };
}

export function getParamColumnsOptions(options: number | string) {
  return {
    disabled: Boolean(Number(options) & ParamSetOption.Disabled),
    config: Boolean(Number(options) & ParamSetOption.InConfig),
    maths: Boolean(Number(options) & ParamSetOption.Calculated),
    details: Boolean(Number(options) & ParamSetOption.Detalization),
    markable: Boolean(Number(options) & ParamSetOption.Markable),
    collapsed: Boolean(Number(options) & ParamSetOption.Collapsed),
    reference: Boolean(Number(options) & ParamSetOption.Reference),
    redNegative: Boolean(Number(options) & ParamSetOption.RedNegative),
    forParams: Boolean(Number(options) & ParamSetOption.Inspector),
    forTable: Boolean(Number(options) & ParamSetOption.Grid),
    sort: Boolean(Number(options) & ParamSetOption.Sortable),
    filterByField: Boolean(Number(options) & ParamSetOption.Filterable),
    proto: Boolean(Number(options) & ParamSetOption.Override),
    hidden: Boolean(Number(options) & ParamSetOption.Hidden),
    period: Boolean(Number(options) & ParamSetOption.History),
    group: Boolean(Number(options) & ParamSetOption.CanGroup),
  };
}
