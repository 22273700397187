import { Box, Button, Grid, Paper } from "@mui/material";
import { tokenProcessingTest } from "../../../../TokenProcessing/TokenProcessing";
import { AxiosRequest } from "../../../../Url";
import { getObjText } from "../../../Tools/Requests";
import ContextMenu from "../../../NotWorkArea(Side&Head)/ContextMenu";
import { formEdit } from "../../../Tools/Tools";

export default function ContentModalWindow({ gridpanelRef, props, request }) {
  function checkRequest(request) {
    if (!request) {
      if (props.selectKey === "RecordIndex") {
        return {
          Name: "RecordIndex",
          Value: gridpanelRef.current.grid.source.activeRecord,
        };
      }

      const reqValue =
        props.KeyField === "Code"
          ? gridpanelRef.current.grid.source.getFieldValueSync("CODE")
          : gridpanelRef.current.grid.source.getFieldTextSync(props.KeyField ? props.KeyField.toUpperCase() : "ID");
      return {
        Name: props.selectKey ? props.selectKey : "ObjRef",
        Value: reqValue,
      };
    }

    return request;
  }

  async function onButtonSelect(event, func, RequestId, from, req) {
    const request = checkRequest(req);
    const ClickedButton = event.target.value;
    const textValue = gridpanelRef.current.grid.source.getFieldTextSync("NAME");
    let json;
    let data;

    if (props.onEdit && props.setValue && props.setInputValue) {
      const objRef =
        props.KeyField === "Code"
          ? gridpanelRef.current.grid.source.getFieldValueSync("CODE")
          : gridpanelRef.current.grid.source.getFieldTextSync(props.KeyField ? props.KeyField.toUpperCase() : "ID");
      if (props.record) {
        props.onEdit({
          tag: objRef,
          name: props.Name,
          fieldname: props.FieldName,
          current: props.Current,
          record: props.record,
          textchanged: "0",
          setValue: props.setValue,
          setInputValue: props.setInputValue,
          type: props.Module,
          requestId: props.RequestID,
          addInfo: props.addInfo,
        });
        return;
      } else if (props.setValue && props.setInputValue) {
        props.onEdit({
          value: (await getObjText(props.Module, props.ObjType, objRef, "0")).Text,
          tag: objRef,
          name: props.Name,
          setValue: props.setValue,
          setInputValue: props.setInputValue,
          type: props.Module,
          requestId: props.RequestID,
          addInfo: props.addInfo,
        });
        return;
      }
    }

    await formEdit("1", func, request, RequestId, from);
  }

  return (
    <>
      <Box style={{ width: "100%", height: "calc(100%-20)" }}>
        <ContextMenu
          for={"grid"}
          refs={gridpanelRef.current}
          // data={MenuJson}
          // onRightClick={() => {
          //   return GetMenu({ state: true, status: true }, DocumentLayout);
          // }}
          // onMenuItemClick={ContextMenuHandler}
        >
          <Grid
            id="rcgrid_panel"
            ref={gridpanelRef}
            data-objref={gridpanelRef}
            style={{
              position: "absolute",
              width: "calc(100% - 20px)",
              height: "calc(100% - 103px)",
            }}
          >
            <Paper elevation={4}></Paper>
          </Grid>
        </ContextMenu>
      </Box>
      <Box style={{ width: "100%", display: "flex" }}>
        <Box
          style={{
            marginLeft: "auto",
            position: "absolute",
            bottom: "8px",
            right: "10px",
          }}
        >
          <Button
            size="small"
            variant="outlined"
            value={1}
            onClick={(e) => {
              // Временная функция закрытия модального окна
              // заменить на closeQuery
              // e.target.closest("SMART-WINDOW").parentElement.remove();
              onButtonSelect(e, props.func ? props.func : undefined, props.RequestID, props.from, request);
            }}
            style={{
              textTransform: "none",
              width: 75,
              display: props.hiddenButton ? "none" : props.Buttons && (props.Button & 1) === 0 ? "none" : "",
            }}
            className="button_Modal_Select"
          >
            Выбрать
          </Button>
          <Button
            size="small"
            variant="outlined"
            value={2}
            style={{ textTransform: "none", marginLeft: 10, width: 75 }}
            className="button_Modal_Close"
            onClick={() => {
              return formEdit("0", props.func, props.RequestID, props.from, props.request);
            }}
          >
            Отмена
          </Button>
        </Box>
      </Box>
    </>
  );
}
