import { setColor } from "../testEditor";

export const colorList = [
  { label: "Черный", id: "0", color: setColor(0) },
  { label: "Бордовый", id: "128", color: setColor(128) },
  { label: "Зеленый", id: "32768", color: setColor(32768) },
  { label: "Коричневый", id: "32896", color: setColor(32896) },
  { label: "Темно-синий", id: "8388608", color: setColor(8388608) },
  { label: "Пурпурный", id: "8388736", color: setColor(8388736) },
  { label: "Темно-зеленый", id: "8421376", color: setColor(8421376) },
  { label: "Серый", id: "8421504", color: setColor(8421504) },
  { label: "Светло-серый", id: "12632256", color: setColor(12632256) },
  { label: "Красный", id: "255", color: setColor(255) },
  { label: "Светло-зеленый", id: "65280", color: setColor(65280) },
  { label: "Желтый", id: "65535", color: setColor(65535) },
  { label: "Голубой", id: "16711680", color: setColor(16711680) },
  { label: "Фиолетовый", id: "16711935", color: setColor(16711935) },
  { label: "Светло-голубой", id: "16776960", color: setColor(16776960) },
  { label: "Белый", id: "16777215", color: setColor(16777215) },
  { label: "Подсказка", id: "14811135", color: setColor(14811135) },
];
