import React from "react";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import { useEffect, useRef } from "react";
import { closeModal } from "../Tools/modalManager";

export default function MessageBox(props) {
  const ButtonBlockRef = useRef(null);

  useEffect(() => {
    if (ButtonBlockRef.current) {
      const firstButton = ButtonBlockRef.current.querySelector("button");
      if (firstButton) firstButton.focus();
    }
  }, []);

  const Button_Ok = 1;
  const Button_Cancel = 2;
  const Button_Yes = 4;
  const Button_No = 8;
  const Button_Abort = 16;
  const Button_Retry = 32;
  const Button_Ignore = 64;
  const Button_All = 128;
  const Button_NoToAll = 256;
  const Button_YesToAll = 512;

  //   const DlgRes_None = 0;
  const DlgRes_OK = 1;
  const DlgRes_Cancel = 2;
  const DlgRes_Abort = 3;
  const DlgRes_Retry = 4;
  const DlgRes_Ignore = 5;
  const DlgRes_Yes = 6;
  const DlgRes_No = 7;
  //   const DlgRes_Close = 8;
  //   const DlgRes_Help = 9;
  //   const DlgRes_TryAgain = 10;
  const DlgRes_Continue = 11;
  const DlgRes_All = DlgRes_Continue + 1;
  const DlgRes_NoToAll = DlgRes_All + 1;
  const DlgRes_YesToAll = DlgRes_NoToAll + 1;

  function handleClickButton(ev) {
    closeModal();
    props.onSelect?.(ev, props.RequestID, props.setReturnValue, props.from);
  }

  let icon;
  //   let window;
  if (props.DlgType === 2) {
    icon = (
      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="48" height="48" viewBox="0 0 48 48">
        <path fill="#2196f3" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path>
        <path
          fill="#fff"
          d="M22 22h4v11h-4V22zM26.5 16.5c0 1.379-1.121 2.5-2.5 2.5s-2.5-1.121-2.5-2.5S22.621 14 24 14 26.5 15.121 26.5 16.5z"
        ></path>
      </svg>
    );
  }
  if (props.DlgType === 3) {
    icon = (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48" height="48">
        <path fill="#2196f3" d="M44,24c0,11.044-8.956,20-20,20S4,35.044,4,24S12.956,4,24,4S44,12.956,44,24z" />
        <path
          fill="#e3f2fd"
          d="M23.758 31.145c-.727 0-1.328.234-1.797.711-.469.473-.703 1.074-.703 1.805 0 .719.23 1.313.688 1.781.461.469 1.063.703 1.816.703.75 0 1.352-.234 1.813-.703.457-.469.688-1.063.688-1.781 0-.73-.234-1.332-.703-1.805C25.094 31.387 24.48 31.145 23.758 31.145zM29.184 14.586C27.969 13.527 26.266 13 24.07 13c-2.176 0-3.895.559-5.152 1.672-1.191 1.055-1.824 2.504-1.902 4.328H17v1h4.5l.008-.703c.02-.824.262-1.473.723-1.941.457-.473 1.074-.707 1.84-.707 1.617 0 2.422.871 2.422 2.617 0 .578-.152 1.129-.465 1.648-.309.52-.934 1.195-1.871 2.027-.938.828-1.582 1.672-1.934 2.527-.355.855-.531 2.086-.531 3.531h3.98l.063-1.023c.113-1.012.563-1.891 1.352-2.645l1.262-1.191c.98-.949 1.672-1.813 2.063-2.59.395-.781.594-1.609.594-2.484C31 17.137 30.395 15.645 29.184 14.586z"
        />
      </svg>
    );
  }
  if (props.DlgType === 1) {
    icon = (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" width="48" height="48">
        <path
          fill="#f78f8f"
          d="M40,77.5C19.322,77.5,2.5,60.678,2.5,40S19.322,2.5,40,2.5S77.5,19.322,77.5,40S60.678,77.5,40,77.5 z"
        />
        <path
          fill="#c74343"
          d="M40,3c20.402,0,37,16.598,37,37S60.402,77,40,77S3,60.402,3,40S19.598,3,40,3 M40,2 C19.013,2,2,19.013,2,40s17.013,38,38,38s38-17.013,38-38S60.987,2,40,2L40,2z"
        />
        <path fill="#fff" d="M37 20H43V60H37z" transform="rotate(-134.999 40 40)" />
        <path fill="#fff" d="M37 20H43V60H37z" transform="rotate(-45.001 40 40)" />
      </svg>
    );
  }
  if (props.DlgType === 0) {
    icon = (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" width="48" height="48">
        <path
          fill="#ffeea3"
          d="M3.209,35.5c-0.617,0-1.169-0.319-1.477-0.853c-0.309-0.534-0.309-1.172,0-1.706L18.523,3.858 c0.308-0.534,0.86-0.853,1.477-0.853s1.169,0.319,1.477,0.853l16.791,29.083c0.309,0.534,0.309,1.171,0,1.706 c-0.308,0.534-0.86,0.853-1.477,0.853H3.209z"
        />
        <path
          fill="#ba9b48"
          d="M20,3.506c0.209,0,0.73,0.059,1.044,0.603l16.791,29.083c0.314,0.544,0.105,1.025,0,1.206 C37.73,34.578,37.419,35,36.791,35H3.209c-0.628,0-0.94-0.422-1.044-0.603c-0.105-0.181-0.314-0.662,0-1.206L18.956,4.109 C19.27,3.564,19.791,3.506,20,3.506 M20,2.506c-0.743,0-1.486,0.368-1.91,1.103L1.299,32.691C0.45,34.162,1.511,36,3.209,36h33.582 c1.698,0,2.759-1.838,1.91-3.309L21.91,3.609C21.486,2.873,20.743,2.506,20,2.506L20,2.506z"
        />
        <path
          fill="#36404d"
          d="M20 27.517999999999997A1.696 1.696 0 1 0 20 30.91 1.696 1.696 0 1 0 20 27.517999999999997zM18.643 12.929H21.357V25.143H18.643z"
        />
      </svg>
    );
  }
  const buttons = [];
  const buttonConfig = [
    { condition: Button_Ok, label: "ОК", value: DlgRes_OK },
    { condition: Button_Cancel, label: "Отмена", value: DlgRes_Cancel },
    { condition: Button_Yes, label: "Да", value: DlgRes_Yes },
    { condition: Button_No, label: "Нет", value: DlgRes_No },
    { condition: Button_Abort, label: "Прервать", value: DlgRes_Abort },
    { condition: Button_Retry, label: "Повторить", value: DlgRes_Retry },
    { condition: Button_Ignore, label: "Пропустить", value: DlgRes_Ignore },
    { condition: Button_All, label: "Для всех", value: DlgRes_All },
    { condition: Button_NoToAll, label: "Нет для всех", value: DlgRes_NoToAll },
    { condition: Button_YesToAll, label: "Да для всех", value: DlgRes_YesToAll },
  ];
  const buttonFlags = props.Buttons || Button_Ok;
  buttonConfig.forEach(({ condition, label, value }) => {
    if (buttonFlags & condition) {
      buttons.push(
        <Button
          size="small"
          style={{ textTransform: "none" }}
          variant="outlined"
          className="button_message"
          value={value}
          data-requestid={props.RequestID}
          onClick={handleClickButton}
        >
          {label}
        </Button>,
      );
    }
  });

  return (
    <>
      <Box style={{ width: "100%", display: "flex" }}>
        <Box style={{ width: "48px", display: "flex", alignItems: "center" }}>{icon}</Box>
        <Box
          style={{
            width: "calc(100% - 48px)",
            display: "flex",
            alignItems: "center",
            padding: "10px",
            whiteSpace: "break-spaces",
          }}
        >
          {" "}
          {props.Message}
        </Box>
      </Box>
      <Box style={{ display: "flex", justifyContent: "center" }} ref={ButtonBlockRef} tabIndex={"-1"}>
        {buttons.map((btn, index) => {
          return (
            <Box key={`message-box-btn_${index}`} style={{ width: "max-content", padding: "5px" }}>
              {btn}
            </Box>
          );
        })}
      </Box>
    </>
  );
}
