import { atom } from "jotai";
import { GetOptionsForUser } from "./components/Url";

const userOptions = GetOptionsForUser();

export const isAuthorized = atom(false);
//salary
export const renderingIdAtom = atom({ renderingId: 0, personId: 0 });
export const groupIdAtom = atom(0);
export const salaryRangeAtom = atom({ year: "", month: "", range: "0" });
export const leavesOpenAtom = atom(false);
export const isAdminAtom = atom<boolean>(userOptions?.isAdmin);
export const personsTableCurrentId = atom(null);
