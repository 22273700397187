import { openDB } from "idb";

export const initDB = async (dbName: string, storeName: string) => {
  const db = await openDB(dbName, 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(storeName)) {
        db.createObjectStore(storeName, { keyPath: "id", autoIncrement: true });
      }
    },
  });
  return db;
};

export const saveDataBD = async (dbName: string, storeName: string, data: Record<string, unknown>) => {
  const db = await initDB(dbName, storeName);
  const tx = db.transaction(storeName, "readwrite");
  const store = tx.objectStore(storeName);
  await store.add(data);
  await tx.done;
};

export const getDataBD = async (dbName: string, storeName: string, id: number) => {
  const db = await initDB(dbName, storeName);
  return await db.get(storeName, id);
};

export const getAllDataBD = async (dbName: string, storeName: string) => {
  const db = await initDB(dbName, storeName);
  return await db.getAll(storeName);
};
