import React from "react";
import { tokenProcessingTest } from "../../../../../TokenProcessing/TokenProcessing";
import { AxiosRequest } from "../../../../../Url";
import MessageBox from "../../../../Module/MessageBox";
import { openModal } from "../../../../Tools/modalManager";
import { TITLE_MESSAGE_MODAL_WINDOW } from "../../../../Tools/ProjectX";
import { getStyleWindow } from "../../../../Tools/Tools";
import { createFullGroupItem, createFullItem } from "./treeCreationTools";

// запрос опций для контекстного меню
export async function getTreeView(id, props) {
  const params = new Map();
  params.set("prefix", props.Module);
  params.set("comand", "GetTreeViewMenu");
  params.set("ID", id);
  params.set("ObjType", props.ObjType ? props.ObjType : "0");
  params.set("Info", props.Info ? props.Info : "0");
  params.set("Internal", props.Internal ? props.Internal : "1");
  const json = await AxiosRequest(true, params);
  const array = (json && json.Items && json.Items.items) || (json && json.items);

  return (
    {
      arr: array.map((item) => ({ ...item, Caption: item.Name })),
    } || {
      arr: array.map((item) => ({ ...item, Caption: item.Name })),
    }
  );
}

// запрос изменения дерева
async function ChangeTreeNodes(data, request) {
  const params = new Map();
  params.set("prefix", data.Module);
  params.set("comand", "ChangeTreeNodes");
  params.set("ObjType", data.ObjType);
  params.set("Info", "0");
  if (data.id) params.set("ID", data.id);
  if (data.parentID) params.set("ParentID", data.parentID);
  params.set("Text", data.text);
  params.set("Command", data.comand);
  return AxiosRequest(true, params, request);
}

// создание раздела на текущем уровне через меню
export async function createContextItem(data) {
  const id = data.parent === data.tree.current ? "0" : data.parent.id.split("_")[1];

  const json = await ChangeTreeNodes(
    {
      text: "Новый раздел",
      id: "0",
      command: "Insert",
      parentID: id,
      ObjType: data.props.ObjType || "0",
      Module: data.props.Module,
    },
    {
      ObjType: data.props.ObjType ? data.props.ObjType : "0",
      Text: "Новый раздел",
      Command: "Insert",
      ParentID: id,
    },
  );

  // создание нового раздела
  const newItem = createFullItem({
    ...data.createData,
    id: json.ID,
    text: `Новый раздел`,
    image: json.Image,
  });

  // Добавление элемента в ДОМ
  if (data.item === data.tree.current) {
    if (data.tree.current.children.length) {
      data.tree.current.lastChild.style.borderBottom = "1px solid rgb(238, 238, 238)";
    }
    data.tree.current.appendChild(newItem);
  } else {
    if (data.parent.children.length) {
      data.parent.lastChild.style.borderBottom = "1px solid rgb(238, 238, 238)";
    }
    data.parent.appendChild(newItem);
  }
  // выбор созданного элемента
  data.tree.current.changeSelected(newItem.querySelector(".tree-item"));
}

// преобразование текущего раздела в группу и добавление элемента внутри через меню
export async function createContextGroup(data) {
  const json = await ChangeTreeNodes(
    {
      text: "Новый раздел",
      id: "0",
      comand: "Insert",
      parentID: data.id,
    },
    {
      ObjType: data.props.ObjType ? data.props.ObjType : "0",
      Text: "Новый раздел",
      Command: "Insert",
      ParentID: data.id,
    },
  );

  const newItem = createFullItem({
    ...data.createData,
    id: json.ID,
    text: `Новый раздел`,
    image: json.Image,
  });

  // создание нового элемента если дерево пустое
  if (data.item === data.tree.current) {
    if (data.parent.children.length) {
      data.parent.lastChild.style.borderBottom = "1px solid rgb(238, 238, 238)";
    }
    data.tree.current.appendChild(newItem);
    data.tree.current.changeSelected(newItem.querySelector(".tree-item"));
    return;
  }

  // добавление элемента если группа уже существует
  const ulEl = data.item.closest("UL");
  if (ulEl.classList.contains("tree-group")) {
    const container = ulEl.querySelector(`LI`);
    if (container.children.length) {
      container.lastChild.style.borderBottom = "1px solid rgb(238, 238, 238)";
    }
    container.appendChild(newItem);
  } else {
    // замена элемента на группу и создание нового элемента внутри нее
    const newItemsGroup = createFullGroupItem({
      ...data.createData,
      id: data.id,
      text: data.text,
    });
    const newParent = data.parent.closest("LI") || data.tree.current;
    newParent.replaceChild(newItemsGroup, data.item.parentElement);
    newParent.querySelector(`#tree-group_${data.id}`).appendChild(newItem);
  }
  // выбор созданного элемента
  data.tree.current.changeSelected(newItem.querySelector(".tree-item"));
}

// удаление разделов через меню
export async function deleteContextItem(data) {
  const id = data.parent === data.tree.current ? "0" : data.parent.id.split("_")[1];

  const json = await ChangeTreeNodes(
    {
      text: data.text,
      id: data.id,
      comand: "Delete",
      parentID: id,
    },
    {
      ID: data.id,
      ObjType: data.props.ObjType ? data.props.ObjType : "0",
      Text: data.text,
      Command: "Delete",
      ParentID: id,
    },
  );

  if (json.error) return;

  const ulEl = data.item.closest("UL");
  const expndBttn = ulEl.querySelector(".expandButton");

  if (expndBttn) expndBttn.click();

  setTimeout(() => {
    const liEl = ulEl.querySelector("LI");

    if (liEl && liEl.children.length) {
      openModal(<MessageBox DlgType={1} Message={"Раздел содержит вложенные подразделы, сначала удалите их."} />, {
        style: getStyleWindow("MessageBox"),
        hiddenButton: true,
        blockMaximize: true,
        blockSaveLocation: true,
        blockResize: true,
        Title: TITLE_MESSAGE_MODAL_WINDOW[1],
      });
      return;
    }

    const prevUl = ulEl.previousElementSibling;
    if (prevUl) {
      prevUl.style.borderBottom = "";
    }

    ulEl.remove();

    const selectedItem =
      (prevUl && prevUl.querySelector(".tree-item")) || data.tree.current.querySelector(".tree-item");
    if (!selectedItem) return;
    data.tree.current.changeSelected(selectedItem);
  });
}

export async function treeManipulationsById(data) {
  const item = data.tree.current.querySelector(".tree-item-selected") || data.tree.current.querySelector(".tree-item");
  let parent = item.closest("LI") || data.tree.current;
  const itemContainer = item.closest("UL");

  data.item = item;
  data.parent = parent;
  data.itemContainer = itemContainer;

  const programs = {
    0: () => {},
    1: () => {},
    2: () => {},
    4: () => {},
    8: () => {},
    16: () => {},
    32: () => {},
    456: (data) => {
      moveIn(data);
    },
    457: () => {
      moveUp(data);
    },
    458: () => {
      moveDown(data);
    },
    default: () => {
      console.error("Не обработанное действие контекстного меню дерева");
    },
  };

  const runProgram = programs[Number(data.ID)] || programs.default;

  const json = await HandleTreeMenu(data);

  if (json && json.Token) {
    tokenProcessingTest(json, {
      func: (result) => {
        runProgram({ ...data, result });
      },
      from: "tree",
    });
    return;
  }

  json && (await runProgram());
}

async function HandleTreeMenu(data) {
  const params = new Map();
  params
    .set("prefix", data.props.Module)
    .set("comand", "HandleTreeMenu")
    .set("ObjType", data.props.ObjType)
    .set("Info", data.props.Info)
    .set("Menu", data.ID)
    .set("ID", data.item.id);
  if (data.WSM) params.set("WSM", "1");
  return AxiosRequest(true, params);
}

// перемещение элемента вверх
function moveUp(data) {
  const prevEl = data.itemContainer.previousElementSibling;
  if (prevEl) {
    data.parent.insertBefore(data.itemContainer, prevEl);
  }
}

// перемещение элемента вниз
function moveDown(data) {
  const nextEl = data.itemContainer.nextElementSibling;
  if (nextEl) {
    data.parent.insertBefore(nextEl, data.itemContainer);
  }
}

// перемещение через цепочку запросов
function moveIn(data) {
  if (data.result && data.result.NeedRefresh === "1") {
    // обновление дерева
    data.tree.current.refreshTree(data.item.id);
  }
}
