import React, { useState } from "react";

//Компоненты
// import SignIn from "./Singin/SingIn";

//Стили и пропсы
// import WrapperRightSide from "./MainPage/WrapperMainFile";
import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "../provider/ThemeProvider";

import { lazy, Suspense } from "react";
import LoadingMask from "./MainPage/NotWorkArea(Side&Head)/LoadingMask";
import CheckMobile from "./lk/CheckMobile";
import { webkcrypt_invoke } from "./TokenProcessing/TokenProcessing";
import webkcrypt_globals from "./dualplug/webkcrypt_globals";
import cadesplugin_api from "./dualplug/cadesplugin_api";
import webkcrypt_create from "./dualplug/webkcrypt";
import { saveDataBD } from "./MainPage/Tools/IndexedBd";
import { isSessionActive } from "./MainPage/Tools/Tools";
import { useSetAtom } from "jotai";
import { isAuthorized } from "../atoms";

const SignIn = lazy(() => import("./Singin/SingIn")); //обычный вход в смету
const WrapperRightSide = lazy(() => import("./MainPage/WrapperMainFile")); //Рабочая часть где все секции  т д
const SignInLK = lazy(() => import("./lk/SignIn")); //вход в лк
const SignUpUmi = lazy(() => import("./lk/SignUp/SignUpUMI")); //регистрация для лк УМИ
const SignUp = lazy(() => import("./lk/SignUp/SignUp")); // стандратная регистрация для ЛК
const RestorePassword = lazy(() => import("./lk/RestorePassword")); //смена пароля только для ЛК уми

export const Theme = React.createContext("");

function showNotif() {
  //отображаем уведомление для янао
  if (window.checkNotifyme === true && window.textContentNotifyme) {
    const div = document.createElement("div");
    const closeDiv = document.createElement("div");
    closeDiv.id = "close";
    closeDiv.onclick = () => {
      div.style.display = "none";
    };
    div.id = "notifyme";
    div.className = "display";
    const content = `<p>Уважаемые пользователи!</p>
    ${window.textContentNotifyme}`;
    div.innerHTML = content;
    div.appendChild(closeDiv);
    document.body.appendChild(div);
  }
}

async function logErrorToDatabase(error) {
  const data = [];
  const errorObj = {
    filename: error.filename,
    line: error.lineno,
    message: error.message,
  };
  data.push(errorObj);
  await saveDataBD("smeta", "errors", errorObj);
}

const Wrapper = () => {
  const pjson = require("./../../package.json");
  const [checkedSession, setCheckedSession] = useState(false);
  const setIsAutorized = useSetAtom(isAuthorized);

  const checkSessionActive = async () => {
    const isActive = await isSessionActive();
    const licGUID = localStorage.getItem("login");
    if (licGUID) {
      sessionStorage.setItem("LicG", licGUID);
    }
    if (!isActive) {
      localStorage.setItem("login", "");
    }
    setIsAutorized(isActive);
    setCheckedSession(true);
  };

  React.useEffect(() => {
    webkcrypt_globals();
    cadesplugin_api();
    webkcrypt_create();
    window.StimateVersion = "81." + pjson.version + "/38";
    checkSessionActive();
    window.addEventListener("error", logErrorToDatabase);
    return () => {
      window.removeEventListener("error", logErrorToDatabase);
    };
  }, []);

  String.prototype.replaceAt = function (index, replacement) {
    return this.substring(0, index) + replacement + this.substring(index + replacement.length);
  };

  function detectMob() {
    const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }

  window.onload = function () {
    webkcrypt_invoke("webkcrypt-check-updates", null, (event) => {
      // nothing
    });
    showNotif();
  };
  if (!checkedSession) return <LoadingMask />;
  if (window.BASE_UMI !== "1" && detectMob() && window.innerWidth < 650) {
    return <CheckMobile />;
  } else
    return (
      <main>
        {/* <BrowserRouter basename={window.basename}> */}
        <HashRouter>
          <Routes>
            <Route
              path="/"
              element={
                <Suspense fallback={<LoadingMask />}>
                  {window.hideEnter !== undefined && window.hideEnter === true ? <SignInLK /> : <SignIn />}
                </Suspense>
              }
            />
            {window.hideEnter !== true && (
              <Route
                path="/lk"
                element={
                  <Suspense fallback={<LoadingMask />}>
                    <SignInLK />
                  </Suspense>
                }
              />
            )}
            {window.BASE_UMI === "1" && (
              <Route
                path="/restorepassword"
                element={
                  <Suspense fallback={<LoadingMask />}>
                    <RestorePassword />
                  </Suspense>
                }
              />
            )}
            <Route
              path="/signup"
              element={
                <Suspense fallback={<LoadingMask />}>{window.BASE_UMI === "1" ? <SignUpUmi /> : <SignUp />}</Suspense>
              }
            />
            <Route
              path="/main"
              element={
                <ThemeProvider>
                  <Suspense fallback={<LoadingMask />}>
                    <WrapperRightSide />
                  </Suspense>
                </ThemeProvider>
              }
            />
          </Routes>
        </HashRouter>
        {/* </BrowserRouter> */}
      </main>
    );
};

export default Wrapper;
