import { AxiosRequest } from "../../../Url";
import { getRightsData } from "../../Tools/Tools";
import { parseColumns } from "../GridTools/parseColumnTools";

export async function accountsTableGrid(grid, source, props, data) {
  let json = await PostHandleTable(props.Module, props.Path);
  source.onHandleRequest = handleRequest(props, source);

  if (json) {
    source.rights = getRightsData(json.Rights);

    grid.setColumns(
      parseColumns(json, undefined, undefined, {
        Path: props.Path,
        Module: props.Module,
      }),
    );
  } else grid.defaultColumns = true;

  await source.open();
  if (data.setSelectedRecord) {
    source.onRecordIndexChanged = async function (source) {
      data.setSelectedRecord(source.getFieldTextSync("ID"));
    };
    data.setSelectedRecord(source.getFieldTextSync("ID"));
  }
  if (data.onMultiSelectChange) {
    grid.onMultiSelectChange = function () {
      data.onMultiSelectChange();
    };
  }
  //   if (!props.ObjRef) source.refresh();
  await grid.setSource(source);
  //   if (props.ObjRef) grid.refreshSource(props.ObjRef);
  await grid.updateGridSize();
}

function handleRequest(props, source) {
  return function onHandleRequest(request) {
    const params = new Map();
    params
      .set("prefix", props.Module)
      .set("comand", "HandleTable")
      //   .set("RecordIndex", source.getRecordState())
      .set("Path", props.Path);
    return AxiosRequest(true, params, request);
  };
}

function PostHandleTable(module, Path) {
  const params = new Map();
  params.set("prefix", module).set("comand", "GetTableLayout").set("Path", Path);
  return AxiosRequest(true, params);
}
