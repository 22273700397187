import { AxiosRequest } from "../../../Url";
import { getRightsData } from "../../Tools/Tools";
import { CreateCollapsibleRecords } from "../GridTools/collapsibleRecordsTools";
import { parseColumns } from "../GridTools/parseColumnTools";
import {
  getPaytypesColumnsForPrograms,
  getPaytypesColumnsForSection,
  paytypesColumnsForPrograms,
} from "./Columns/paytypesColumns";

export async function paytypesGrid(grid, source, props, data) {
  source.onHandleRequest = HandleRequestForPaytypes(props);
  //создание стилей для сворачивающихся записей
  source.CreateCollapsibleRecords = CreateCollapsibleRecords;

  await source.open();

  const codeField = source.fields[source.getFieldIndex("Code")];
  //создание дефолтных полей()
  let columns;

  if (data.Columns) {
    switch (data.Columns) {
      case "Section":
        columns = getPaytypesColumnsForSection(codeField);
        break;
      case "Programs":
        columns = paytypesColumnsForPrograms;
        break;
      default:
        break;
    }
  }

  //парсинг полей запроса GetTableLayout
  if (data.json && data.json.Columns) {
    source.rights = getRightsData(data.json.Rights);

    columns = parseColumns(data.json, columns);
    grid.setColumns(columns);
    // source.setKeyFieldName(
    //   data.json.keyField ? data.json.keyField.toUpperCase() : "CODE"
    // );
    source.rights = getRightsData(data.json.Rights);
  } else {
    grid.setColumns(columns);
    source.rights = getRightsData("-1");
  }

  grid.setImageFields(["ID"]);

  source.booksGetImage = (value, field) => {
    if (value < 0) return 3;
    if (field === "$CfgID" && value > 0 && source.getFieldTextSync("ID") > 0) return 2;
  };

  source.onRecordCollapsed = async function (event) {
    let RecordIndex;
    if (event.currentTarget !== undefined) {
      RecordIndex = event.currentTarget.getAttribute("recordindex");
    } else {
      RecordIndex = event;
    }
    let params = new Map();
    params
      .set("prefix", props.Module)
      .set("comand", "RecordCollapsed")
      .set("SectionID", props.SectionID)
      .set("ObjType", props.ObjType)
      .set("RecordIndex", RecordIndex);
    await AxiosRequest(true, params);
    await grid.refreshSource("!");
    if (data.setCountRecords) data.setCountRecords(source.recordCount);
    return;
  };

  if (data.setSelectedRecord) {
    source.onRecordIndexChanged = async function (source) {
      data.setSelectedRecord(source.getFieldValueSync("Code"));
    };
  }
  await source.first();

  await grid.setSource(source);

  if (props.ID) {
    await grid.refreshSource(props.ID);
  }

  await grid.updateGridSize();

  if (data.setCountRecords) data.setCountRecords(source.recordCount);
  if (data.setSelectedRecord) {
    data.setSelectedRecord(source.getFieldValueSync("Code"));
  }
}

export function HandleRequestForPaytypes(props) {
  return function onHandleRequest(request) {
    const params = new Map();
    params
      .set("prefix", props.Module)
      .set("comand", "HandleTable")
      .set("ObjType", props.ObjType)
      .set("SectionID", props.SectionID);
    if (props.GroupID) params.set("GroupID", props.GroupID);
    return AxiosRequest(true, params, request);
  };
}
