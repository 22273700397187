const defaultFieldInfo = {
  DataType: "",
  EditStyle: "",
  Options: "",
  TitleAjust: "",
  TextAjust: "",
  Module: "",
  Values: [],
  Details: "",
  OnEdit: "",
  OnList: "",
  OnHint: "",
  ColumnID: "",
  OnCloseUpList: "",
  OnGetEditStyle: "",
  FieldName: "",
  Path: "",
  ButtonImage: "",
};

export function getPaytypesColumnsForSection(codeField) {
  let columns = {
    title: [
      {
        fieldName: "Code",
        title: "Код",
        width: codeField ? codeField.displayWidth * 15 : 120,
        level: 0,
        fieldInfo: { ...defaultFieldInfo },
        collapseRecord: true,
      },
      {
        fieldName: "Name",
        title: "Наименование",
        width: 300,
        level: 0,
        fieldInfo: { ...defaultFieldInfo },
      },
      {
        fieldName: "ShortName",
        title: "Сокращение",
        width: 120,
        level: 0,
        fieldInfo: { ...defaultFieldInfo, EditStyle: "64" },
      },
      {
        fieldName: "Title",
        title: "Заголовок",
        width: 120,
        level: 0,
        fieldInfo: { ...defaultFieldInfo },
      },
      {
        fieldName: "ShortCode",
        title: "Короткий код",
        width: 120,
        level: 0,
        fieldInfo: { ...defaultFieldInfo },
      },
      {
        fieldName: "ImageIndex",
        title: "Рисунок",
        width: 120,
        level: 0,
        fieldInfo: { ...defaultFieldInfo, EditStyle: "8", Type: "Image" },
      },
      {
        fieldName: "Параметры",
        title: "Набор параметров",
        width: 120,
        level: 0,
        fieldInfo: {
          ...defaultFieldInfo,
          EditStyle: "16",
          DataType: "2789",
          Module: "Params",
        },
      },
      {
        fieldName: "Атрибуты",
        title: "Атрибуты",
        width: 120,
        level: 0,
        fieldInfo: {
          ...defaultFieldInfo,
          EditStyle: "32",
          DataType: "2380",
          Module: "paytypes",
        },
      },
    ],
  };

  return columns;
}

export const paytypesColumnsForPrograms = {
  title: [
    {
      fieldName: "Code2",
      title: "Вид оплаты",
      width: 120,
      level: 0,
      fieldInfo: {
        ...defaultFieldInfo,
        EditStyle: "17",
        Module: "paytypes",
        DataType: "2380",
      },
    },
    {
      fieldName: "ProgID",
      title: "Программа",
      width: 300,
      level: 0,
      fieldInfo: {
        ...defaultFieldInfo,
        EditStyle: "17",
        Module: "programs",
        DataType: "2001",
      },
    },
  ],
};

export const paytypesSettingstColumns = {
  title: [
    {
      fieldName: "ID",
      title: "ID",
      width: 60,
      level: 0,
      hide: true,
      hideInGrid: true,
      hideInCard: true,
      fieldInfo: { ...defaultFieldInfo },
    },
    {
      fieldName: "Code",
      title: "Код",
      width: 60,
      level: 0,
      fieldInfo: { ...defaultFieldInfo },
    },
    {
      fieldName: "Name",
      title: "Наименование",
      width: 300,
      level: 0,
      fieldInfo: { ...defaultFieldInfo },
    },
    {
      fieldName: "Deep",
      title: "Deep",
      width: 30,
      level: 0,
      fieldInfo: { ...defaultFieldInfo },
    },
    {
      fieldName: "ImageIndex",
      title: "ImageIndex",
      width: 30,
      level: 0,
      fieldInfo: { ...defaultFieldInfo, Type: "Image" },
    },
    {
      fieldName: "Options",
      title: "Параметры",
      width: 300,
      level: 0,
      fieldInfo: { ...defaultFieldInfo, EditStyle: "32", Values: [{ label: "Суммировать по сотрудникам", id: 1 }] },
    },
    {
      fieldName: "OnGetAmount",
      title: "Получить сумму",
      width: 120,
      level: 1,
      fieldInfo: { ...defaultFieldInfo, EditStyle: "17", Module: "Programs", DataType: "2001" },
    },
    {
      fieldName: "OnSetAmount",
      title: "Установить сумму",
      width: 120,
      level: 1,
      fieldInfo: { ...defaultFieldInfo, EditStyle: "17", Module: "Programs", DataType: "2001" },
    },
    {
      fieldName: "ParamSetID",
      title: "Раскладка",
      width: 120,
      level: 1,
      fieldInfo: { ...defaultFieldInfo, EditStyle: "16", Module: "Params", DataType: "2789" },
    },
  ],
};
