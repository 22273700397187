import { FC, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl/FormControl";
import InputLabel from "@mui/material/InputLabel/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput/OutlinedInput";
import React from "react";
import InputAdornment from "@mui/material/InputAdornment/InputAdornment";
import IconButton from "@mui/material/IconButton/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
// import ClearIcon from "@mui/icons-material/Clear";
//стили и интерфейсы пропсов
//копмпоненты
import SelectDrx from "./SelectDrx";
import SelectUser from "./SelectUser";
import SelectWorkPlace from "./SelectWorkPlace";
import {
  AxiosRequest,
  CreateCokies,
  UpdateOptionsForUser,
  XMLrequest,
  checkGenLicGuid,
  getURLparam,
  get_cookie,
  hasURLparam,
  removeParam,
} from "../Url";
import { useNavigate } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import SignInDialog from "./SignInDialog";
import ReactDOM from "react-dom";
import ModalSignIn from "./SignInWithCryptoPRO/ModalSignIn";
import { getStyleWindow, isEmptyObject } from "../MainPage/Tools/Tools";
import {
  CheckModalBackGrounds,
  CreateBackgroundForModal,
  executeCertList,
  sendRequest,
  uuid,
} from "../TokenProcessing/TokenProcessing";
import ModalWindow from "../MainPage/Module/ModalWindow";
import ChangePassowrd from "../MainPage/NotWorkArea(Side&Head)/Header/ChangePassowrd";
import { store } from "../../store";

import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getUrlSearh } from "../MainPage/Tools/urlParams";
import { closeModal, openModal } from "../MainPage/Tools/modalManager";
import ChooseCert from "./SignInWithCryptoPRO/ChooseCert";
import DialogSetObjText from "../MainPage/Module/Dialogs/DialogSetObjText";
import { AxiosRequestConfig } from "axios";
import { useAtom } from "jotai";
import { isAdminAtom, isAuthorized } from "../../atoms";
import WorkSpaceInstall from "../MainPage/Tools/WorkSpaceInstall";
// import Button from '@mui/material/Button';
// import {TestPlug} from "./core"

var Secret: any;

function isInstalledWorkSpace() {
  const WorkspaceEx = document.getElementById("WorkspaceEx");
  const isInstalled = WorkspaceEx?.getAttribute("is-installed");
  const isNewApi = WorkspaceEx?.getAttribute("is-new-api");
  const type = window.webkcrypt.type === "cades" || window.webkcrypt.type === "webkcrypt";
  if ((isInstalled && isNewApi) || type) {
    return true;
  } else {
    return false;
  }
}

export function CheckWorkSpace(callBack?: any) {
  if (!isInstalledWorkSpace()) {
    CreateBackgroundForModal(
      <ModalWindow
        Title="Уведомление"
        style={{ height: `${180}px`, width: `${380}px` }}
        Buttons={{
          jsonData: {
            Кнопка$Выбрать: "Ок",
          },
          Params: { Buttons: "1" },
        }}
        onSelect={() => {
          CreateBackgroundForModal(
            <ModalWindow
              Title="Крипто-плагин"
              style={{ height: `${480}px`, width: `${580}px` }}
              Buttons={{
                jsonData: {
                  Кнопка$Выбрать: "Ок",
                },
                Params: { Buttons: "1" },
              }}
              onSelect={() => {
                callBack && callBack();
              }}
            >
              <WorkSpaceInstall />
            </ModalWindow>,
          );
        }}
      >
        <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
          <Grid item sx={{ textAlign: "center" }}>
            В вашем браузере не установлено расширения для работы <strong>КЭП</strong>. Для установки расширения нажите
            кнопку "Ок"{" "}
            {/* <a
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.open("http://stimate.krista.ru/workspaceex/index.html");
              }}
            >
              ссылке
            </a> */}
            .
          </Grid>
        </Grid>
      </ModalWindow>,
    );
  }
  return isInstalledWorkSpace();
}

// export const CheckOtherEnters = (SignInFunc: any, subdata?: any) => {
//   const localStorageLogin: any = localStorage.getItem("login");
//   if (window.MultiSection === "1") return true;
//   if (window.hideEnter !== true) {
//     return true;
//   }
//   if (localStorageLogin !== sessionStorage.getItem("LicG")) {
//     if (localStorageLogin === "" || localStorageLogin === "leave" || localStorageLogin === null) {
//       return true;
//     }
//     CreateBackgroundForModal(
//       <ModalWindow
//         Title="Подтверждение"
//         style={{ height: `${180}px`, width: `${380}px` }}
//         Buttons={{
//           jsonData: {
//             Кнопка$Выбрать: "Выйти везде",
//             Кнопка$Отмена: "Остаться",
//           },
//           Params: { Buttons: "3" },
//         }}
//         onSelect={() => {
//           localStorage.setItem("login", "leave");
//           const licg: any = sessionStorage.getItem("LicG");
//           localStorage.setItem("login", licg);
//           SignInFunc(subdata);
//         }}
//         onClose={() => {
//           if (hasURLparam("Guid")) {
//             window.location.href = getURLparam("from").replaceAll("@", "/");
//           }
//           return;
//         }}
//       >
//         <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
//           <Grid item sx={{ textAlign: "center" }}>
//             Вы уже вошли в аккаунт, будет произведен автоматический выход на другой вкладке.
//           </Grid>
//         </Grid>
//       </ModalWindow>,
//     );
//     return false;
//   } else {
//     return true;
//   }
// };

export const SetClientInfo = (ip?: string) => {
  const pjson = require("./../../../package.json");
  let params = new Map();
  params.set("prefix", "project");
  params.set("comand", "SetClientInfo");
  // params.set('User', 'Project');
  params.set("Host", window.location.host);
  params.set("Port", window.location.port);
  if (ip) params.set("IP", ip);
  params.set("Comp", `${getBrowserType()};${getOS()}`);
  params.set("CVer", "81." + pjson.version);
  // params.set('IVer', 'Project');
  // params.set('TimeZone', 'Project');

  return AxiosRequest(true, params);
};

export function initContext() {
  let params = new Map();
  params.set("prefix", "documents");
  params.set("comand", "InitContext");
  // params.set('User', 'Project');
  params.set("LazyMemos", "1");
  return AxiosRequest(true, params);
}

function getOS() {
  let detectOS = "Unknown OS";

  if (navigator.userAgent.indexOf("Win") != -1) detectOS = "windows";

  if (navigator.userAgent.indexOf("Mac") != -1) detectOS = "MacOS";

  if (navigator.userAgent.indexOf("Linux") != -1) detectOS = "linux";
  return detectOS;
}

export function getBrowserType() {
  const test = (regexp: RegExp) => {
    return regexp.test(navigator.userAgent);
  };

  if (test(/opr\//i)) {
    return "Opera";
  } else if (test(/edg/i)) {
    return "Microsoft Edge";
  } else if (test(/chrome|chromium|crios/i)) {
    return "Chromium";
  } else if (test(/firefox|fxios/i)) {
    return "Mozilla Firefox";
  } else if (test(/safari/i)) {
    return "Apple Safari";
  } else if (test(/trident/i)) {
    return "Microsoft Internet Explorer";
  } else if (test(/ucbrowser/i)) {
    return "UC Browser";
  } else if (test(/samsungbrowser/i)) {
    return "Samsung Browser";
  } else {
    return "Unknown browser";
  }
}

export const AppName = (json: any, drxLocal: any) => {
  let AppName = json["AppName"] === undefined ? json["ConfigName"] + "/" + json["UserName"] : json["AppName"];
  AppName = AppName === undefined || AppName === "undefined/undefined" ? drxLocal : AppName;
  document.title = AppName;
  if (json.UserName !== undefined && !AppName.endsWith("/" + json.UserName)) AppName += "/" + json.UserName;
  CreateCokies("AppName", AppName);
};

export function clearUrl() {
  if (hasURLparam("Guid")) {
    // navigate("main");
    let url = removeParam("Guid", window.location.href);

    if (hasURLparam("authed_by")) {
      UpdateOptionsForUser({ authed_by: getURLparam("authed_by") });
      url = removeParam("authed_by", url);
    } else {
      if (hasURLparam("from")) UpdateOptionsForUser({ from: getURLparam("from") });
    }
    url = removeParam("stimWebSrv", url);
    if (hasURLparam("from")) url = removeParam("from", url);
    window.history.replaceState({ additionalInformation: "Переход" }, "Test", url);
  }
}
// var workspaceConfig: any;
// workspaceConfig = null;

const SignIn = () => {
  document.title = "Вход в систему";

  const navigate = useNavigate();
  const [, setIsAdmin] = useAtom(isAdminAtom);
  const [error, setError] = useState<string | null>("");
  const [drx, setDrx] = useState("");
  const [HasOrgs, setHasOrgs] = useState("0");
  const [user, setUser] = useState("");
  const [workplace, setWorkPlace] = useState("");
  const [password, setPassword] = useState("");
  const [open, setOpen] = useState(false);
  const [secret, setSecret] = useState<any>({});
  const [display, setDisplay] = useState<string>("none");
  const [showPassword, setShowPassword] = React.useState(false);
  const [OrgTypeCls, setOrgTypeCls] = React.useState<any>("");
  const [isSessionActive, setIsSessionActive] = useAtom(isAuthorized);
  // var CERTS_XML_KEY = "certList";

  useEffect(() => {
    if (isEmptyObject(secret) === false) {
      const AppName = secret["AppName"];
      CreateCokies("drx", AppName === undefined ? drx : AppName);
      setIsSessionActive(true);
      navigate("main");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secret]);

  useEffect(() => {
    if (drx !== "") {
      getConfigInfo(drx, user);
    }
  }, [drx, user]);

  useEffect(() => {
    // window.addEventListener("message", handleMessage, false);
    if (hasURLparam("Guid")) {
      sessionStorage.setItem("LicG", getURLparam("Guid"));
      CreateCokies("LicG", getURLparam("Guid"));
      handleSingIn();
    } else {
      checkSessionAndEnter().then((res) => {
        if (res === false) checkGenLicGuid(sessionStorage.getItem("LicG"), true);
      });
    }
    setDisplay(CheckPattern());
    return () => {
      setOpen(false); // This worked for me
    };
  }, []);

  async function checkSessionAndEnter() {
    const isActive = isSessionActive;
    if (isActive === true) {
      navigate(`main` + getUrlSearh());
    }
    return isActive;
  }

  function executeCertListAsXML() {
    executeCertList()
      .then((result: any) => {
        openModal(<ChooseCert data={result.split(/\r?\n/)} onSelect={SignForEnterOnSelect} />, {
          Title: "Выбор сертификата",
          style: {
            height: `${530}px`,
            width: `${580}px`,
            overflow: "hidden",
          },
          hiddenButton: true,
          blockMaximize: true,
          blockResize: true,
        });
      })
      .catch((err: any) => {
        if (CheckWorkSpace()) return;
      });
    return;
  }

  function SignForEnterOnSelect(sn: any) {
    let params = new Map(),
      json: any;
    params.set("comand", "secret");
    json = XMLrequest(params);
    Secret = json["Pkcs7Auth-Secret"].Secret;
    window.webkcrypt
      .make_pkcs7_signature(sn, Secret, true)
      .value.then((value: any) => {
        let fixedVal = value;
        fixedVal.replaceAll(" ", "");
        // fixedVal.replaceAll("\r\n","")
        fixedVal.replaceAll("\\r", "");
        fixedVal.replaceAll("\\n", "1234");
        console.log(fixedVal);

        handleMessage(remove_linebreaks_ss(value));
      })
      .catch((e: any) => {
        console.log(e);
      });
  }

  function remove_linebreaks_ss(str: any) {
    let newstr = "";

    // Looop and traverse string
    for (let i = 0; i < str.length; i++) if (!(str[i] == "\n" || str[i] == "\r")) newstr += str[i];
    return newstr;
  }

  function CheckPattern() {
    if (hasURLparam("Guid") || (window.hideEnter !== undefined && window.hideEnter === true)) {
      return "none";
    } else {
      return "";
    }
  }

  function getConfigInfo(drx: any, user: any) {
    let params = new Map();
    params.set("prefix", "project").set("comand", "getconfiginfo").set("ConfigName", drx);
    if (user) {
      params.set("UserName", user);
    }
    AxiosRequest(true, params).then((res) => {
      setHasOrgs(res.HasOrgs === "1" ? "1" : "0");
    });
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  function handleMessage(message: any) {
    if (message) {
      var response = message.data;
      var JSX;
      let json;

      // JSX = (
      //   <SignInDialog title={"Ошибка"} contentText={response.result} />
      // );
      // JSX = <SignInDialog title={"Успешно"} contentText={response.result}/>
      let params = new Map();
      let LoginData = {
        "Pkcs7Auth-Answer": {
          Answer: message,
        },
      };
      params.set("comand", "answer");
      json = XMLrequest(params, LoginData);
      JSX = <ModalSignIn data={json} secret={Secret} keyFromSelect={message} setSecret={setSecret} />;

      ReactDOM.render(JSX, document.getElementById("renderSignIn"));
    }
  }

  //const ThemeContext = React.createContext('light');

  const GoToMain = (jsonEnter: any, LL?: any, drxLocal?: string) => {
    AppName(jsonEnter, drxLocal);
    UpdateOptionsForUser({ isAdmin: Boolean(jsonEnter.IsAdmin) });
    setIsAdmin(Boolean(jsonEnter.IsAdmin));
    const CreateLL = LL === ",," ? `${jsonEnter["ConfigName"]},${jsonEnter["UserName"]},${jsonEnter["WorkPlace"]}` : LL;
    CreateCokies("LastLogin", CreateLL);
    sessionStorage.setItem("LastLogin", CreateLL);
    clearUrl();
  };

  function NavigateMain(IP: any) {
    SetClientInfo(IP).then(() => {
      const licG: any = sessionStorage.getItem("LicG");
      localStorage.setItem("login", licG);
      initContext().then((initData) => {
        if (IP) {
          UpdateOptionsForUser({ IP: IP });
        }
        if (initData.RefWithCtrl) {
          UpdateOptionsForUser({ RefWithCtrl: initData.RefWithCtrl === "1" });
        }
        setIsSessionActive(true);
        navigate("main" + getUrlSearh());
      });
    });
  }

  const clientPasswordHandler = (event: any) => {
    setPassword(event.target.value);
  };

  function CheckAnswerFromServer(answer?: any) {
    // let test: string;
    setOpen(false);
    switch (answer.Item) {
      case "Password":
        // setError(
        //   answer.content === undefined ? `Пароль неверный.` : answer.content
        // );
        if (answer.NeedNewPW === "1") {
          CreateBackgroundForModal(
            <ModalWindow
              Title={"Смена стандартного пароля пользователя"}
              style={{ height: `${330}px`, width: `${450}px` }}
              hiddenButton={true}
            >
              <ChangePassowrd where={"signin"} />
            </ModalWindow>,
          );
        }
        break;
      default:
        if (answer.content !== undefined) setError(answer.content);
        break;
    }
  }

  const handlekeyDownSignIn = (e: any) => {
    if (e.keyCode === 13 && CheckModalBackGrounds() === 0) {
      // if (!signOuted) SignOut();
      handleSingIn();
    }
  };

  const handleSingIn = () => {
    const LoginLast = drx + "," + user + "," + workplace;

    let params = new Map();
    params.set("comand", "GetUserInfo");
    params.set("ConfigName", encodeURIComponent(drx));
    params.set("UserName", encodeURIComponent(user));
    let rest;
    try {
      rest = XMLrequest(params);
    } catch (error) {
      console.log(error);
    }
    let IP: any;
    // if (HasOrgs !== "1") {
    IP = rest.Server;
    // }
    if (hasURLparam("stimWebSrv") && getURLparam("stimWebSrv") !== undefined) {
      IP = getURLparam("stimWebSrv");
    }
    let LoginData: any = {
      ConfigName: drx,
      UserName: user,
      Password: password,
      WorkPlace: workplace,
      //Comp: "NPO5898",
    };
    params = new Map();
    params.set("comand", "enter");
    if (IP !== "" && IP !== undefined) {
      params.set("IP", IP);
    }
    if (!isEmptyObject(OrgTypeCls)) {
      LoginData.OrgTypeCls = OrgTypeCls.code;
      setOpen(true);
      axiosEnter(params, LoginData, LoginLast, IP);
    } else if (HasOrgs === "1" && isEmptyObject(OrgTypeCls)) {
      if (rest.Configs && rest.Configs.items && rest.Configs.items.length === 1) {
        LoginData.OrgTypeCls = rest.Configs.items[0].Code;
        axiosEnter(params, LoginData, LoginLast, IP);
      } else {
        selectOrg((selectedOrgTypeCls: any) => {
          setOpen(true);
          LoginData.OrgTypeCls = selectedOrgTypeCls;
          axiosEnter(params, LoginData, LoginLast, IP);
        });
      }
    } else {
      axiosEnter(params, LoginData, LoginLast, IP);
    }
    // rest = XMLrequest(params, LoginData);

    // rest["error"] !== undefined
    //   ? CheckAnswerFromServer(rest["error"])
    //   : GoToMain(rest, LoginLast, IP);
    //let res = AxiosRequest(params, "post",LoginData)
    //res.then((responce)=>{(responce)})
    /*
      axios.post(URL(params), JSON.stringify(LoginData)).then((response) => {
        setLoginAnswer(response.data);
        response.data["error"] !== undefined? CheckAnswerFromServer(response.data["error"]["Item"]): GoToMain()
      });*/
  };

  function axiosEnter(params: any, LoginData: object | AxiosRequestConfig<any> | undefined, LoginLast: any, IP: any) {
    AxiosRequest(true, params, LoginData).then((res: any) => {
      if (!isEmptyObject(res)) {
        if (res.error && res.error.NeedNewPW === "1") {
          CheckAnswerFromServer(res.error);
        } else {
          GoToMain(res, LoginLast, drx);
          NavigateMain(IP);
        }
      }
      setOpen(false);
    });
  }

  const selectOrg = (callBack: any) => {
    // setOpen(!open);
    // setLoad(true);
    if (drx !== "") {
      let params = new Map();
      params.set("comand", "GetOrgClsList");
      params.set("ConfigName", encodeURIComponent(drx));
      params.set("UserName", encodeURIComponent(user));
      AxiosRequest(true, params).then((res) => {
        let parseData = res;
        if (res.Items && res.Items.items) {
          parseData = parseData.Items.items.map((val: any) => {
            return {
              text: val.Name,
              id: val.ID,
              code: val.Code,
            };
          });
          openModal(
            <DialogSetObjText
              list={parseData}
              onEdit={(e: any) => {
                setOrgTypeCls({ code: e.code, text: e.value, id: e.tag });
                closeModal();
                callBack && callBack(e.code);
              }}
              // record={data.record}
              // CheckState={data.CheckState}
            />,
            {
              style: getStyleWindow(),
              blockMaximize: true,
              Title: "Выбор организации",
              hiddenButton: true,
            },
          );
        }
      });
      // CreateBackgroundForModal(
      //   <ModalWindow
      //     Title={"Выберите элемент из списка"}
      //     style={getStyleWindow()}
      //     hiddenButton={true}
      //     onClose={() => onCloseSelection(data)}
      //   >

      //   </ModalWindow>
      // );
      // setLoad(false);
      // setUserList(XMLrequest(params));
    }
  };
  // let page = browser.extension.getBackgroundPage()

  return (
    <Container maxWidth="xs" style={{ display: display, marginTop: "2%" }}>
      <CssBaseline />
      <div style={{ display: "flex", justifyContent: "center" }}>
        {window.signOptions?.headerSettings?.image && (
          <img
            src={"logoSmeta.png"}
            alt=""
            width={window.signOptions?.headerSettings?.imageWidth ? window.signOptions?.headerSettings?.imageWidth : ""}
          />
        )}
      </div>
      <Typography variant="h5" color={"#0098ad"} style={{ textAlign: "center" }}>
        {window?.signOptions?.headerSettings?.title !== undefined
          ? window?.signOptions?.headerSettings?.title
          : "Web-Смета"}
      </Typography>
      <Paper variant="elevation" elevation={10}>
        <Box
          sx={{
            px: 3,
            pb: 3,
            pt: 1,
            marginTop: 10,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Grid container direction="column" justifyContent="space-around" alignItems="stretch" spacing={3}>
            <Grid item xs sx={{ display: window?.signOptions?.signButtons?.signCryptoButton ? "" : "none" }}>
              <Button
                style={{
                  backgroundColor: "#0098ad",
                  textTransform: "none",
                }}
                type="submit"
                fullWidth
                variant="contained"
                className="ButtonMargin"
                // onClick={handleSingIn}
                onClick={executeCertListAsXML}
              >
                Вход по ЭП
              </Button>
            </Grid>
            <Grid item xs sx={{ display: window?.signOptions?.signButtons?.helpKristaButton ? "" : "none" }}>
              <Button
                style={{
                  backgroundColor: "#0098ad",
                  textTransform: "none",
                }}
                type="submit"
                fullWidth
                variant="contained"
                className="ButtonMargin"
                // onClick={handleSingIn}
                onClick={() => {
                  window.open("https://help.krista.ru");
                }}
              >
                help.krista.ru
              </Button>
            </Grid>

            <Grid item xs sx={{ display: window?.signOptions?.signButtons?.lkButton ? "" : "none" }}>
              <Button
                style={{
                  backgroundColor: "#0098ad",
                  textTransform: "none",
                }}
                type="submit"
                fullWidth
                variant="contained"
                className="ButtonMargin"
                // onClick={handleSingIn}
                onClick={() => {
                  navigate("/lk" + getUrlSearh());
                }}
              >
                Вход в ЛК
              </Button>
            </Grid>

            <SelectDrx
              drxInfo={drx}
              setBackInfo={setDrx}
              password={undefined}
              userInfo={undefined}
              KeyDown={handlekeyDownSignIn}
            />

            <SelectUser
              HasOrgs={HasOrgs}
              userInfo={user}
              drxInfo={drx}
              setBackInfo={setUser}
              password={undefined}
              selectOrg={selectOrg}
              KeyDown={handleSingIn}
              selectedOrg={OrgTypeCls}
            />

            <Grid item xs>
              {/* <TextField
                fullWidth
                // name="password"
                label="Пароль"
                type="password"
                // id="password"
                onChange={clientPasswordHandler}
                inputProps={{
                  autoComplete: "current-password",
                }}
                onKeyDown={handlekeyDownSignIn}
                value={password}
              /> */}
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Пароль</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  onChange={clientPasswordHandler}
                  value={password}
                  onKeyDown={handlekeyDownSignIn}
                  endAdornment={
                    <InputAdornment position="end" sx={{ mr: 1 }}>
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                      {/* <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          setPassword("");
                        }}
                        onMouseDown={() => {
                          setPassword("");
                        }}
                        edge="end"
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton> */}
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
            </Grid>

            <SelectWorkPlace
              drxInfo={drx}
              userInfo={user}
              workPlaceInfo={workplace}
              setBackInfo={setWorkPlace}
              password={undefined}
              KeyDown={handlekeyDownSignIn}
            />

            {/*  ПОТОМ СДЕЛАТЬ GETUSERINFO */}

            <Grid item>
              <Button
                style={{ backgroundColor: "#0098ad", textTransform: "none" }}
                type="submit"
                fullWidth
                variant="contained"
                className="ButtonMargin"
                onClick={() => {
                  // if (!signOuted) SignOut();
                  handleSingIn();
                }}
              >
                Войти
              </Button>
            </Grid>
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                color: "red",
                marginLeft: "24px",
              }}
            >
              {error !== null ? `${error}` : ""}
            </div>
            <Grid id="renderSignIn"></Grid>
          </Grid>
        </Box>
      </Paper>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

export default SignIn;
