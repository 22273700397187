import { AxiosRequest } from "../../../Url";
import { getRightsData } from "../../Tools/Tools";
import { parseColumns } from "../GridTools/parseColumnTools";

export async function kladrGrid(grid, source, props, data) {
  const json = await kladrtGetTable(props);

  source.onHandleRequest = HandleRequest(props, data);

  await source.open();

  if (json) {
    source.rights = getRightsData(json.Rights);

    const columns = parseColumns(json);
    columns.title[1].fieldName = "Name";
    grid.setColumns(columns);
  } else grid.defaultColumns = true;

  await grid.setSource(source);
  await grid.refreshSource(props.RecordIndex);
  await grid.updateGridSize();
}

function HandleRequest(props, data) {
  return function onHandleRequest(request) {
    const params = new Map();
    params.set("prefix", "kladr").set("comand", "HandleTable").set("ObjType", props.ObjType).set("Code", props.Code);
    return AxiosRequest(true, params, request);
  };
}

const kladrtGetTable = (props) => {
  let param = new Map();
  param.set("prefix", "kladr").set("comand", "GetTableLayout").set("ObjType", props.ObjType).set("Code", props.Code);
  return AxiosRequest(true, param);
};
