import { AxiosRequest } from "../../../Url";
import { defaultFieldInfo, getRightsData } from "../../Tools/Tools";
import { CreateCollapsibleRecords } from "../GridTools/collapsibleRecordsTools";
import { parseColumns } from "../GridTools/parseColumnTools";

export async function tablesGrid(grid, source, props, data) {
  source.onHandleRequest = HandleRequest(props);
  //создание стилей для сворачивающихся записей
  source.CreateCollapsibleRecords = CreateCollapsibleRecords;

  await source.open();

  const codeField = source.fields[source.getFieldIndex("Code")];
  //создание дефолтных полей()
  let columns = { title: [] },
    dest = {};
  dest.fieldName = "Code";
  dest.title = "№ п.п.";
  dest.width = codeField ? codeField.displayWidth * 15 : 120;
  dest.level = 0;
  dest.fieldInfo = { ...defaultFieldInfo, EditStyle: "64" };
  dest.collapseRecord = true;
  columns.title.push(dest);

  dest = {};
  dest.fieldName = "FullName";
  dest.title = "Наименование";
  dest.width = 320;
  dest.level = 0;
  dest.fieldInfo = { ...defaultFieldInfo };
  columns.title.push(dest);

  dest = {};
  dest.fieldName = "Name";
  dest.title = "Сокращение";
  dest.width = 120;
  dest.level = 1;
  dest.fieldInfo = { ...defaultFieldInfo, EditStyle: "64" };
  columns.title.push(dest);

  dest = {};
  dest.fieldName = "Title";
  dest.title = "Заголовок";
  dest.width = 120;
  dest.level = 1;
  dest.fieldInfo = { ...defaultFieldInfo };
  columns.title.push(dest);

  dest = {};
  dest.fieldName = "Norms";
  dest.title = "Виды учета";
  dest.width = 120;
  dest.level = 0;
  dest.fieldInfo = { ...defaultFieldInfo, EditStyle: "32" };
  columns.title.push(dest);

  dest = {};
  dest.fieldName = "PayTypeCode";
  dest.title = "Вид оплаты";
  dest.width = 120;
  dest.level = 0;
  dest.fieldInfo = {
    ...defaultFieldInfo,
    EditStyle: "17",
    Module: "paytypes",
    DataType: "2380",
  };
  columns.title.push(dest);

  if (data.json) {
    source.rights = getRightsData(data.json.Rights);

    columns = parseColumns(data.json, columns);
    grid.setColumns(columns);
    source.setKeyFieldName(data.json.keyField ? data.json.keyField.toUpperCase() : "CODE");
    source.rights = getRightsData(data.json.Rights);
  } else grid.setColumns(columns);

  if (data.setSelectedRecord) {
    source.onRecordIndexChanged = async function (source) {
      data.setSelectedRecord(source.getFieldTextSync("ID"));
    };
  }

  source.rights = getRightsData("-1");

  //   grid.setImageFields(["ID"]);
  //   source.booksGetImage = (value, field) => {
  //     if (value < 0) return 3;
  //     if (field === "$CfgID" && value > 0 && source.getFieldTextSync("ID") > 0)
  //       return 2;
  //   };

  source.onRecordCollapsed = async function (event) {
    let RecordIndex;
    if (event.currentTarget !== undefined) {
      RecordIndex = event.currentTarget.getAttribute("recordindex");
    } else {
      RecordIndex = event;
    }
    let params = new Map();
    params
      .set("prefix", props.Module)
      .set("comand", "RecordCollapsed")
      .set("SectionID", props.SectionID)
      .set("ObjType", props.ObjType)
      .set("RecordIndex", RecordIndex);
    await AxiosRequest(true, params);
    await grid.refreshSource("!");
    if (data.setCountRecords) data.setCountRecords(source.recordCount);
    return;
  };

  await grid.setSource(source);

  if (props.ID) {
    await grid.refreshSource(props.ID);
  } else await grid.refreshSource();
  await grid.updateGridSize();
  if (data.setCountRecords) data.setCountRecords(source.recordCount);
  if (data.setSelectedRecord) {
    data.setSelectedRecord(source.getFieldTextSync("ID"));
  }
}

function HandleRequest(props) {
  return function onHandleRequest(request) {
    const params = new Map();
    params
      .set("prefix", props.Module)
      .set("comand", "HandleTable")
      .set("ObjType", props.ObjType)
      .set("SectionID", props.SectionID)
      .set("CanChangeActual", "1");
    return AxiosRequest(true, params, request);
  };
}
