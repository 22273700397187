import { Tabs, TabItem } from "smart-webcomponents-react/tabs";
import ChangePassowrd from "./ChangePassowrd";
import ThemeChange from "./ThemeChange";
// import { SetMiniTabs } from "../../Tools/Tools";
import { Grid } from "@mui/material";
import { AxiosRequest, GetOptionsForUser } from "../../../Url";
import { useEffect, useState } from "react";
import WorkSpaceInstall from "../../Tools/WorkSpaceInstall";

export default function Options() {
  var pjson = require("./../../../../../package.json");
  const version = "81." + pjson.version;
  const IP = GetOptionsForUser()?.IP;
  const [ServerInfo, setServerInfo] = useState({});

  useEffect(() => {
    let params = new Map();
    params.set("comand", "Info");
    params.set("Info", "1");
    params.set("JSON", "1");
    AxiosRequest(true, params).then((res) => {
      setServerInfo(res);
    });
  }, []);
  return (
    <Tabs id="optionsForUser" style={{ width: "100%", height: "100%" }}>
      <TabItem
        id={"ChangePassowrdTab"}
        label={"Безопасность"}
        style={{
          textTransform: "none",
          width: "100%",
          height: "100%",
          padding: "0px",
          display: "inline-block",
        }}
      >
        <ChangePassowrd />
      </TabItem>
      <TabItem
        id={"ThemeTab"}
        label={"Оформление"}
        style={{
          textTransform: "none",
          width: "100%",
          height: "100%",
          padding: "0px",
          display: "inline-block",
        }}
      >
        <ThemeChange />
      </TabItem>
      <TabItem
        id={"AboutTab"}
        label={"О сайте"}
        style={{
          textTransform: "none",
          width: "100%",
          height: "100%",
          padding: "0px",
          display: "inline-block",
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          style={{ height: "100%", paddingLeft: 4, paddingTop: 4 }}
          spacing={1}
          // xs={{ m: 4 }}
        >
          <Grid item>{`Версия сайта: ${window.StimateVersion}`}</Grid>
          <Grid item>{`Версия сервера: ${ServerInfo.Version}`}</Grid>
          <Grid
            item
            onClick={() => {
              navigator.clipboard.writeText(sessionStorage.getItem("LicG"));
            }}
          >{`Идентификатор сессии: ${sessionStorage.getItem("LicG")}`}</Grid>
          {IP !== undefined && !window.hideServerRedirection && <Grid item sx>{`Сервер перенаправления: ${IP}`}</Grid>}
        </Grid>
      </TabItem>
      <TabItem
        id={"ChangePassowrdTab"}
        label={"Крипто-плагин"}
        style={{
          textTransform: "none",
          width: "100%",
          height: "100%",
          padding: "0px",
          display: "inline-block",
        }}
      >
        <WorkSpaceInstall />
      </TabItem>
    </Tabs>
  );
}
