import { AxiosRequest } from "../../../Url";
import { getRightsData } from "../../Tools/Tools";
import { paytypesSettingstColumns } from "./Columns/paytypesColumns";

export async function paytypesSettingsGrid(grid, source, props, data) {
  source.onHandleRequest = HandleRequestForPaytypesSettings(props);

  await source.open();

  let columns = paytypesSettingstColumns;

  grid.setColumns(columns);
  source.rights = getRightsData("-1");

  if (data.setSelectedRecord) {
    source.onRecordIndexChanged = async function (source) {
      data.setSelectedRecord(source.getFieldValueSync("Code"));
    };
  }

  await source.first();

  await grid.setSource(source);

  if (props.ID) {
    await grid.refreshSource(props.ID);
  }

  if (data.setSelectedRecord) {
    data.setSelectedRecord(source.getFieldValueSync("Code"));
  }

  await grid.updateGridSize();
}

function HandleRequestForPaytypesSettings(props) {
  return function onHandleRequest(request) {
    const params = new Map();
    params
      .set("prefix", props.Module)
      .set("comand", "HandleTable")
      .set("ObjType", props.ObjType)
      .set("SectionID", props.SectionID);
    return AxiosRequest(true, params, request);
  };
}
