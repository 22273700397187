import { AxiosRequest } from "../../../Url";
import { PostHandleTable } from "../../Tools/Requests";
import { defaultFieldInfo, getModalSectionID, getRightsData } from "../../Tools/Tools";
import { parseColumns } from "../GridTools/parseColumnTools";

export async function booksGrid(grid, source, props, data) {
  let json = await PostHandleTable(props.Module, props.ObjType, undefined, props.SectionID);
  source.onHandleRequest = handleRequest(props);

  await source.open();

  if (json) {
    source.rights = getRightsData(json.Rights);

    if (json.ReadOnly === "1") grid.OnlyViewMode = true;

    let columns = { title: [] },
      dest = {};
    dest.fieldName = "ID";
    dest.title = "Ключ";
    dest.width = 80;
    dest.level = 0;
    dest.hide = true;
    dest.hideInGrid = true;
    dest.hideInCard = true;
    dest.Options = 8192;
    dest.fieldInfo = { ...defaultFieldInfo };
    columns.title.push(dest);
    grid.setColumns(parseColumns(json, columns));
  } else grid.defaultColumns = true;
  if (!props.ObjRef) await source.refresh();
  await grid.setSource(source);
  if (props.ObjRef) await grid.refreshSource(props.ObjRef);
  await grid.updateGridSize();
}

function handleRequest(props) {
  return function onHandleRequest(request) {
    const params = new Map();
    params
      .set("prefix", props.Module)
      .set("comand", "HandleTable")
      .set("ObjType", props.ObjType)
      .set("SectionID", props.SectionID ? props.SectionID : getModalSectionID());
    return AxiosRequest(true, params, request);
  };
}
