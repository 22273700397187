import { store } from "../../../store";

type ModalPortalArray = { content: React.ReactElement | HTMLElement; props?: ModalWindowsProps }[];

type ModalWindowsButtons =
  | "Кнопка$ОК"
  | "Кнопка$Отмена"
  | "Кнопка$Выбрать"
  | "Кнопка$Да"
  | "Кнопка$Нет"
  | "Кнопка$Прервать"
  | "Кнопка$Повторить"
  | "Кнопка$Пропустить"
  | "Кнопка$Для всех"
  | "Кнопка$Нет для всех"
  | "Кнопка$Да для всех";

interface ModalWindowsProps {
  /** CSS стили модального окна. В них можно задать ограничения максимального/минимального размера окна.*/
  style?: React.CSSProperties;
  /** Скрытие кнопки "Во весь экран" и блокировка события двойного клика на шапке модального окна.*/
  blockMaximize?: boolean;
  /**Функция, которая будет вызвана при закрытии модального окна через кнопку "Отмена"(если она не скрыта) или через кнопку в шапке документа. */
  onClose?: () => void;
  /** Функция, которая будет вызвана при нажатии стандартной кнопки "Выбрать" если она не скрыта.*/
  onSelect?: () => void;
  /** Заголовок модального окна.*/
  Title?: string;
  /**Скрытие стандартных кнопок. */
  hiddenButton?: boolean;
  Module?: string;
  ObjType?: number | string;
  ObjRef?: number | string;
  MultiSel?: string;
  /**Число от которого будет считаться zIndex у элемента заднего фона модального окна. */
  zIndex?: number;
  /** Блокировка изменений размеров модального окна.*/
  blockResize?: boolean;
  Buttons?:
    | number
    | {
        jsonData: { [key in ModalWindowsButtons]?: string };
        Params: { Buttons: number };
      };
  /**Блокировка сохранения позиционирования и размеров модального окна */
  blockSaveLocation?: boolean;
  /**Установка фокуса на модальное окно */
  setFocus?: boolean;
  closing?: boolean;
}

let setModals: React.Dispatch<React.SetStateAction<ModalPortalArray>> = () => {};
let timer: NodeJS.Timeout | null = null;

export function initModalManager(setModalsFunction: React.Dispatch<React.SetStateAction<ModalPortalArray>>) {
  setModals = setModalsFunction;
}

export function openModal(content: React.ReactElement | HTMLElement, props?: ModalWindowsProps) {
  const newModal = {
    content: content,
    props: props,
  };
  setModals((prevModals) => [...prevModals, newModal]);
  return closeModal;
}

export function closeModal() {
  const closingFunction = (prevModals: ModalPortalArray) => {
    if (prevModals.length === 1) {
      return [];
    }
    return prevModals.slice(0, prevModals.length - 1);
  };

  if (!store.getState().theme.animations) return setModals(closingFunction);
  if (!timer) {
    setModals((prevModals: ModalPortalArray) => {
      const newModals = [...prevModals];
      const lastModal = newModals[newModals.length - 1];
      if (lastModal.props) {
        lastModal.props.closing = true;
      } else {
        lastModal.props = { closing: true };
      }
      return newModals;
    });
    timer = setTimeout(() => {
      setModals(closingFunction);
      timer = null;
    }, 226);
  }
}
