import React from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import { AxiosRequest, GetOptionsForUser, XMLrequest } from "../../Url";
import { NestedMenuItem } from "./NestedMenuOrigin/NestedMenuItem";
import { ImgURL } from "../../Url";
// import axios from "axios";
import { tokenProcessingTest } from "../../TokenProcessing/TokenProcessing";
// import { DialogSlide } from "../Module/Dialogs/DialogSlide";
import { useDispatch, useSelector } from "react-redux";
import { setWorkPlaceTools } from "../../../store/themeSlice";
import { extend, isEmptyObject } from "./Tools";
import { Divider } from "@mui/material";
//
//https://mui.com/components/toggle-button/
//
const SectionToolsJS = (props) => {
  // const [Program, setProgram] = React.useState([<></>]);

  //const [requestId,setRequestId] = React.useState();
  const [menuBarSection, setMenuBarSection] = React.useState([]);
  const [buttonsSection, setButtonsSection] = React.useState([]);
  const workplaceTools = useSelector((state) => state.theme.workPlaceTools);
  const dispatch = useDispatch();
  const [dataButtonsDefault, setDataButtonsDefault] = React.useState();
  const [open1, setOpen1] = React.useState(false);
  const [menuBarDefault, setMenuBarDefault] = React.useState(undefined);
  const [ID, setID] = React.useState();
  const [AssMass, setAssMass] = React.useState(new Map());
  const [anchorElAss, setAnchorElAss] = React.useState(new Map());
  const [subForms, setSubForms] = React.useState(undefined);
  // const [titleGrid, setTitleGrid] = React.useState("");
  const [display, setDisplay] = React.useState("flex");

  React.useLayoutEffect(() => {
    GetWorkPlaceTools();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (props.ID !== undefined) {
      // GetSectionTools();
    } else {
    }
  }, [props.ID]);

  React.useEffect(() => {
    if (subForms !== undefined) {
      setTimeout(() => {
        tokenProcessingTest(subForms);
      }, 10);
    }
  }, [subForms]);

  const handleClick = (event) => {
    setOpen1(!open1);
    const id = event.currentTarget.getAttribute("id");
    setAssMass(AssMass.set(id, true));
    setAnchorElAss(anchorElAss.set(id, event.currentTarget));
    setID(id);
  };

  const handleClose = (event) => {
    setOpen1(!open1);
    // const id = event.currentTarget.getAttribute("id");
    setAssMass(AssMass.set(ID, false));
    setAnchorElAss(anchorElAss.set(ID, null));
  };

  const handleClickItemMenu = (event) => {
    setOpen1(!open1);
    // const id = event.currentTarget.getAttribute("id");
    const Path = event.currentTarget.getAttribute("path");
    const Token = event.currentTarget.getAttribute("token");
    const Params = event.currentTarget.getAttribute("params");
    const Module = event.currentTarget.getAttribute("module");
    // const Type = event.currentTarget.getAttribute("type");
    setAssMass(AssMass.set(ID, false));
    setAnchorElAss(anchorElAss.set(ID, null));
    if (Path !== null) {
      handeleExecToolprogram(event);
    } else if (Token === "SelectObject") {
      let ObjType = getObjType(Params);
      let CLSID = getCLSID(Params);
      GetSelectObject(ObjType, CLSID);
      //GetDialogParam(ObjType)
    } else if (Token) {
      let params = new Map();
      params.set("prefix", Module);
      params.set("comand", Token);
      params.set("WSM", "1");
      AxiosRequest(true, params).then((res) => {
        tokenProcessingTest(res, { propsId: props.ID });
      });
    }

    //setDid(<DialogContainer title={id} contentText={JSXInfoAboutClickedItem} />)
  };

  function getObjType(str) {
    let pos = str.indexOf("ObjType=") + 8;
    return str.substring(pos, str.indexOf(",", pos));
  }

  function getCLSID(str) {
    let pos = str.indexOf("CLSID=") + 6;
    return str.substring(pos, str.indexOf(",", pos));
  }

  const GetSectionTools = async () => {
    let params = new Map(),
      json;
    params.set("prefix", "tools");
    params.set("comand", "GetSectionTools");
    params.set("SectionID", props.ID);
    json = await AxiosRequest(true, params);
    if (isEmptyObject(json)) {
      setMenuBarSection([]);
    } else {
      if (json["Buttons"]) setButtonsSection(json["Buttons"]);
      if (json) setMenuBarSection(json["MenuBar"]);
    }
  };

  const GetWorkPlaceTools = async () => {
    let json = {};

    if (workplaceTools === undefined) {
      let params = new Map();
      params.set("prefix", "config");
      params.set("comand", "GetWorkPlaceTools");
      json = await AxiosRequest(true, params);
      dispatch(setWorkPlaceTools(Object.assign({}, json)));
    } else {
      json = Object.assign({}, workplaceTools);
    }

    if (json === undefined && isEmptyObject(json) && GetOptionsForUser()?.authed_by !== undefined) {
      DisableBar();
    } else {
      if (json["Buttons"]) setDataButtonsDefault(json["Buttons"]["Button"]);
      if (json["MenuBar"]) {
        setMenuBarDefault(json["MenuBar"]);
        CreateMap(json["MenuBar"]);
        GetSectionTools();
      } else {
        if (props.setHeight) props.setHeight("95%");
        if (props.setLoad) props.setLoad(true);
        GetSectionTools();
        // DisableBar();
      }
    }
  };

  function DisableBar() {
    setDisplay("none");
    if (props.setHeight) {
      props.setHeight("100%");
    }
  }

  const GetSelectObject = (ObjType, CLSID) => {
    let param = new Map(),
      json;
    param.set("prefix", "ABLUtils");
    param.set("comand", "SelectObject");
    param.set("CLSID", CLSID);
    param.set("ObjType", ObjType);
    param.set("OnlyView", "1");
    param.set("WSM", "1");
    json = XMLrequest(param);
    setSubForms(json);
  };

  function CreateMap(List) {
    for (const key of Object.keys(List)) {
      setAnchorElAss(anchorElAss.set(key, null));
      setAssMass(AssMass.set(key, false));
    }
  }

  function RecItems(jsonItems, CurrentID) {
    if (jsonItems !== undefined) {
      let DeepFirst, Token, Params, Module, openSet, Path, Image;
      let assemblyLists = [];

      for (const [key, value] of Object.entries(jsonItems)) {
        Token = jsonItems[key]["Token"];
        Path = jsonItems[key]["Path"];
        Image = jsonItems[key]["Image"];
        Params = jsonItems[key]["Params"];
        Module = jsonItems[key]["Module"];
        DeepFirst = value;

        // ArrItems = Object.keys(DeepFirst);

        if (Token !== undefined || Path !== undefined) {
          //это то что будет внутри item
          let prS = Image === undefined ? 0.5 : 0.2;
          let Type = jsonItems[key]["Type"];
          assemblyLists.push(
            <Grid key={key}>
              <MenuItem
                token={Token}
                params={Params}
                path={Path}
                type={Type}
                module={Module}
                id={key}
                onClick={handleClickItemMenu}
                style={{ height: "32px", marginLeft: 2 }}
              >
                <Grid sx={{ pr: prS, pt: 0.5 }}>
                  {Image === undefined ? (
                    <div style={{ paddingLeft: "13px" }}></div>
                  ) : (
                    ImgURL(Image, { width: "16px", height: "16px" })
                  )}
                </Grid>
                {key}
              </MenuItem>
            </Grid>,
          );
        } else if (typeof DeepFirst === "object" && Object.keys(DeepFirst).length > 0) {
          // это item который будет распахиваться
          openSet = AssMass.get(CurrentID);
          if (key !== "Image" && key !== "-") {
            assemblyLists.push(
              <Grid key={key}>
                <NestedMenuItem
                  style={{
                    paddingLeft: "10px",
                    height: "32px",
                    marginLeft: 3,
                  }}
                  leftIcon={
                    Image === undefined ? (
                      <div style={{ paddingLeft: "16px" }}></div>
                    ) : (
                      ImgURL(Image, {
                        height: "16px",
                        width: "16px",
                        marginLeft: 7,
                        marginTop: 3,
                        marginRight: -5,
                      })
                    )
                  }
                  label={key}
                  parentMenuOpen={openSet}
                >
                  {RecItems(DeepFirst, CurrentID)}
                </NestedMenuItem>
              </Grid>,
            );
          }
        }
      }
      return assemblyLists;
    }
  }

  function Rec(jsonItems, jsonItemsSection) {
    if (jsonItems !== undefined && jsonItemsSection !== undefined) {
      // (jsonItems)
      let DeepFirst,
        // ArrItems,
        openSet = false,
        anchorElset,
        Token;
      let assemblyLists = [];
      //CreateMap(jsonItems)
      let assignedButtons = {};
      assignedButtons = extend(true, jsonItems, jsonItemsSection);
      for (const [key, value] of Object.entries(assignedButtons)) {
        Token = value["Token"];
        DeepFirst = value;
        // ArrItems = Object.keys(DeepFirst);
        // (key + "----------------------------------")

        if (Token === undefined) {
          //это то что будет внутри item
          openSet = AssMass.get(key);
          anchorElset = anchorElAss.get(key);

          assemblyLists.push(
            <Grid item key={key}>
              <Button id={key} onClick={handleClick} style={{ textTransform: "none", height: "25px" }}>
                {key}
              </Button>
              <Menu
                id={key}
                anchorEl={anchorElset}
                open={openSet ? true : false}
                onClose={handleClose}
                sx={{
                  boxShadow:
                    "rgba(0, 0, 0, 0.2) 0px 5px 1px -3px, rgba(0, 0, 0, 0.14) 0px 8px 30px 1px, rgba(0, 0, 0, 0.12) 0px 3px 30px 2px",
                }}
              >
                {RecItems(DeepFirst, key)}
                {/* {RecItems(jsonItemsSection[key], key)} */}
              </Menu>
            </Grid>,
          );
        }
      }
      return assemblyLists;
    }
  }

  async function handeleExecToolprogram(event, type) {
    ///tools~ExecToolProgram
    let Path = event.currentTarget.getAttribute("path"),
      Type,
      params = new Map();
    if (Path === "SetupProjectParams") {
      params.set("prefix", "config");
      params.set("comand", "SetupProjectParams");
      params.set("WSM", "1");
      AxiosRequest(true, params).then((res) => {
        tokenProcessingTest(res, { propsId: props.ID });
      });
      return;
    }
    Type = event.currentTarget.getAttribute("type");
    params.set("prefix", "tools");
    params.set("comand", "ExecToolProgram");
    params.set("Path", encodeURIComponent(Path));
    params.set("Type", Type);
    params.set("Checked", "0");
    params.set("WSM", "1");
    AxiosRequest(true, params, props.postData).then((res) => {
      tokenProcessingTest(res, { propsId: props.ID });
    });
  }

  const RenderButtons = (ButtonsLocal, WichButton) => {
    let newKey = 0;
    if (ButtonsLocal !== undefined && WichButton === "SectionTools") {
      let items = [],
        Path,
        Module,
        Type;

      items.push(
        <Grid key={"default-button-section-tools" + 0} item>
          {props.defaultButton}
        </Grid>,
      );
      for (const [key, value] of Object.entries(ButtonsLocal)) {
        Path = backValue(value, "Path");
        Type = backValue(value, "Type");
        Module = backValue(value, "Module");
        items.push(
          <Grid key={key} item toolsbutton="">
            <Tooltip title={key} arrow>
              <IconButton
                path={Path}
                type={Type}
                module={Module}
                color="primary"
                component="span"
                onClick={handeleExecToolprogram}
              >
                {ImgURL(backValue(value, "Image"))}
              </IconButton>
            </Tooltip>
          </Grid>,
        );
      }
      return items;
    } else if (ButtonsLocal !== undefined && WichButton === "WorkPlace") {
      let items = [],
        Token,
        Hint;
      Hint = ButtonsLocal.Hint;
      Token = ButtonsLocal.Token;
      items.push(
        <Grid key={`button-${newKey}`} item toolsbutton="">
          <Tooltip title={Hint} arrow>
            <IconButton
              path={Token}
              // module={value.Module}
              color="primary"
              component="span"
              onClick={(e) => handeleExecToolprogram(e)}
            >
              {ImgURL(ButtonsLocal.Image)}
            </IconButton>
          </Tooltip>
        </Grid>,
      );
      newKey++;
      return items;
    }
  };

  const backValue = (value, param) => {
    return value[param];
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      sx={{ pl: 1 }}
      id={"tools" + props.ID}
      style={{ flex: "0 1 auto", display: display }}
    >
      <Grid item>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          id={"buttons_for_section" + props.ID}
        ></Grid>
      </Grid>
      <Grid item>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
          {RenderButtons(dataButtonsDefault, "WorkPlace")}
          <Divider orientation="vertical" flexItem />
          {RenderButtons(buttonsSection, "SectionTools")}
          <Divider orientation="vertical" flexItem />
          {Rec(menuBarDefault, menuBarSection)}
        </Grid>
      </Grid>

      <Grid id="RenderDefault"></Grid>
    </Grid>
  );
};

export default SectionToolsJS;
