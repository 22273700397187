// файл для запросов

import { AxiosRequest, XMLrequest } from "../../Url";
import { ObjType } from "./Consts";
import { getModalSectionID } from "./Tools";

/////////////////////////////////////////////////////////////////////////////////
/////////////////////////Запросы для Справочника Организации/////////////////////
/////////////////////////////////////////////////////////////////////////////////

// запрос деталей(Табов)
export async function GetTableLayout(module, ObjType, GroupID, SectionID) {
  let params = new Map();
  params
    .set("prefix", module)
    .set("comand", "GetTableLayout")
    .set("GroupID", GroupID)
    .set("ObjType", ObjType)
    .set("SectionID", SectionID);
  return AxiosRequest(true, params);
}

// запросы параметров Адрес и Дополнительно
export function handleParameters(module, value, selectedRecordID, objref) {
  const params = new Map();
  params.set("prefix", module).set("comand", value.Token);
  if (selectedRecordID) params.set("GroupId", selectedRecordID);
  if (objref) params.set("ObjRef", objref);
  if (value.Token === "HandleParams") params.set("NeedRefresh", "1");

  return AxiosRequest(true, params);
}

export function GetAddressUseKladr() {
  const params = new Map();
  params.set("prefix", "address").set("comand", "GetAddressUseKladr");
  return AxiosRequest(true, params);
}

// запрос для создания новых sources для основных таблицы
export function handleTable(data) {
  let groupID, subRequest;

  if (data.selectionMode) {
    if (data.GroupID.match(/[0-9]+/)) {
      groupID = data.GroupID.match(/[0-9]+/)[0];
      subRequest = { Groups: data.GroupID, MultiSel: "1" };
    } else {
      subRequest = { MultiSel: "1" };
    }
  } else groupID = data.GroupID;

  return function handleRequest(request) {
    const params = new Map();
    params
      .set("prefix", data.Module)
      .set("comand", "HandleTable")
      .set("ObjType", data.ObjType)
      .set("SectionID", data.SectionID)
      .set("GroupID", groupID)
      .set("ID", data.selectedRecordID ? data.selectedRecordID : "0");
    if (data.OrgTypeCls !== undefined) params.set("OrgTypeCls", data.OrgTypeCls);
    return AxiosRequest(true, params, subRequest ? { ...request, ...subRequest } : request);
  };
}

// запрос для создания новых columns для основной таблицы
export const PostHandleTable = (module, ObjType, GroupID, SectionID, OrgTypeCls, Path) => {
  const params = new Map();
  params
    .set("prefix", module)
    .set("comand", "GetTableLayout")
    .set("ObjType", ObjType)
    .set("SectionID", SectionID ? SectionID : getModalSectionID());
  if (GroupID !== undefined) params.set("GroupID", GroupID);
  if (Path) params.set("Path", Path);
  if (OrgTypeCls !== undefined) params.set("OrgTypeCls", OrgTypeCls);
  return AxiosRequest(true, params);
};

// запрос для создания новых columns для таблицы Счета
export function postHandleTableAccounts(module, ObjType, SectionID) {
  const params = new Map()
    .set("prefix", module)
    .set("comand", "GetTableLayout")
    .set("ObjType", ObjType)
    .set("SectionID", SectionID);

  return AxiosRequest(true, params);
}

// запрос для создания новых sources для таблицы Счета
export function handleTableAccounts(module, ObjType, GroupID, SectionID) {
  return function handleAcc(request) {
    let params = new Map();
    params
      .set("prefix", module)
      .set("comand", "HandleAccounts")
      .set("ObjType", ObjType)
      .set("GroupID", GroupID)
      .set("SectionID", SectionID);
    return AxiosRequest(true, params, { ...request, first: "0" });
  };
}

// Запрос для работы поиска
export function GetDialogParamsForSearch(module, ObjType, ObjRef) {
  const params = new Map();
  params.set("prefix", module).set("comand", "GetDialogParams").set("ObjType", ObjType).set("ObjRef", ObjRef);
  return XMLrequest(params);
}

export function GetDocDialogParams(ObjType, ObjRef) {
  const params = new Map();
  params.set("prefix", "documents").set("comand", "GetDialogParams").set("ObjType", ObjType).set("ObjRef", ObjRef);
  return AxiosRequest(true, params);
}

// Запрос для полей документов в справочнике организации
export function PostHandleTableDoc(module, DocCfgID, SectionID) {
  const params = new Map();
  params
    .set("prefix", module)
    .set("comand", "GetDocumentLayout")
    .set("DocCfgID", DocCfgID)
    .set("SectionID", SectionID)
    .set("GridState", "1");
  return AxiosRequest(true, params);
}

// запрос для отрисовки записей таблицы документов
export function handleTableDoc(module, DocCfgID, GroupID, SectionID) {
  return (request) => {
    const params = new Map();
    params
      .set("prefix", module)
      .set("comand", "HandleDocument")
      .set("DocCfgID", DocCfgID)
      .set("SectionID", SectionID)
      .set("ParentID", GroupID);
    return AxiosRequest(true, params, request);
  };
}

// запрос для получения текстогого значения выбранной организации
export function getObjText(module, ObjType, ObjRef, flag) {
  const params = new Map();
  params
    .set("prefix", module)
    .set("comand", "GetObjectText")
    .set("Flag", flag)
    .set("ObjType", ObjType)
    .set("ObjRef", ObjRef);
  return AxiosRequest(true, params);
}

export function setParamObjectText(module, ObjType, ObjRef, value, flag = "-1") {
  const params = new Map();
  if (module) {
    params.set("prefix", module).set("comand", "SetObjectText").set("Flag", flag);
  } else {
    params.set("prefix", "programs").set("comand", "SetParamObjectText");
  }
  params
    .set("ObjType", ObjType || "0")
    .set("ObjRef", ObjRef || "0")
    .set("Text", value === undefined ? "" : value);
  return AxiosRequest(true, params);
}

// сохранение состояния справочника
export function saveBookState(module) {
  let params = new Map();
  params.set("prefix", module).set("comand", "SaveBookState");
  return XMLrequest(params);
}

export async function treeRequest(module, ObjType, SectionID) {
  // organizations~GetGroupTree?LicGUID=0FC2363F4C23BD3E9E453A9ECFB19C7E&ObjType=2310&SectionID=494&Info=0&UsedDate=0&ObjRef=0
  const params = new Map();
  params
    .set("prefix", module)
    .set("comand", "GetGroupTree")
    .set("ObjType", ObjType)
    .set("SectionID", SectionID)
    .set("Info", "0")
    .set("UsedDate", "0")
    .set("ObjRef", "0");
  return AxiosRequest(true, params);
}

export function getBookContextMenu(module, ObjType, ObjRef, SectionID) {
  // GET /organizations~GetBookContextMenu?LicGUID=DFC8B0AC4CA6612D22434082622A9219&ObjRef=921&ObjType=2320&SectionID=100
  const params = new Map();
  params.set("prefix", module).set("comand", "GetBookContextMenu").set("ObjType", ObjType).set("ObjRef", ObjRef);
  if (SectionID) params.set("SectionID", SectionID);
  return XMLrequest(params);
}

/////////////////////////////////////////////////////////////////////////////////
/////////////////////////Запросы для Поиска//////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////

// запрос параметров для поиска
export function getSearchParams(GroupId, Module) {
  const params = new Map();
  params.set("prefix", Module).set("comand", "GetSearchParams").set("GroupID", GroupId);
  return AxiosRequest(true, params);
}

// запуск поиска
export function initSearch(Module, Path, Index, ObjType) {
  const params = new Map();
  params
    .set("prefix", Module)
    .set("comand", "InitSearch")
    .set("Path", Path)
    .set("Index", Index)
    .set("ObjType", ObjType)
    .set("smart", "1");
  return AxiosRequest(true, params);
}

// остановка поиска
export function stopSearch(Module) {
  const params = new Map();
  params.set("prefix", Module).set("comand", "StopSearch").set("smart", "1");
  return AxiosRequest(true, params);
}

export function getParent(ObjRef) {
  const params = new Map();
  params
    .set("prefix", "persons")
    .set("comand", "GetObjectText")
    .set("Flag", "3")
    .set("ObjType", "2040")
    .set("ObjRef", ObjRef);
  const json = XMLrequest(params);
  return json;
}

export function setTreeGroup(module, objType, SectionID, ParentID) {
  const params = new Map();
  params
    .set("prefix", module)
    .set("comand", "SetTreeGroup")
    .set("DocCfgID", objType)
    .set("SectionID", SectionID)
    .set("ParentID", ParentID);
  return AxiosRequest(true, params);
}

// GET /documents~GetColumnDetails?LicGUID=90E5DD5942BF9F359C3E61ADE768244A&DocCfgID=3011&ParentID=366452

export function GetColumnDetails(DocCfgID, ParentID, Path, FieldName, Module) {
  const params = new Map();
  params.set("prefix", Module ? Module : "documents").set("comand", "GetColumnDetails");
  if (Path) {
    params.set("Path", Path);
    params.set("FieldName", FieldName);
    params.set("ID", ParentID);
  } else {
    if (DocCfgID) params.set("DocCfgID", DocCfgID);
    params.set("ParentID", ParentID);
  }
  return AxiosRequest(true, params);
}

export function EditColumnField(data) {
  const params = new Map();
  params
    .set("prefix", data.Module)
    .set("comand", "EditColumnField")
    .set("DocCfgID", data.DocCfgID)
    .set("SectionID", data.SectionID)
    .set("ID", data.ID)
    .set("DocID", data.DocID ? data.DocID : "0")
    .set("MasterID", data.MasterID ? data.MasterID : "0")
    .set("Reference", data.Reference ? data.Reference : "0")
    .set("WSM", "1");
  return AxiosRequest(true, params);
}

export function HandleSamples(data) {
  const params = new Map();
  params.set("prefix", "programs").set("comand", "HandleSamples");
  if (data.SectionID) params.set("SectionID", data.SectionID);
  const json = AxiosRequest(true, params, data);
  return json;
}

export function ApplyFilter(data) {
  const params = new Map();
  params
    .set("prefix", data.Module)
    .set("comand", "ApplyFilter")
    .set("Filter", data.Filter !== undefined ? data.Filter : "")
    .set("Parents", data.Parents ? data.Parents : "0");
  if (data.SectionID) params.set("SectionID", data.SectionID);
  const json = AxiosRequest(true, params, data);
  return json;
}
// GET /documents~GetColumnHint?LicGUID=E64051504F01503EF9CED09D0AD7E63B&ID=3853&DocCfgID=3008&DocID=20694170
export function GetColumnHintDocs(data) {
  const params = new Map();
  params
    .set("prefix", "documents")
    .set("comand", "GetColumnHint")
    .set("ID", data.ID !== undefined ? data.ID : "0")
    .set("DocCfgID", data.DocCfgID)
    .set("DocID", data.DocID !== undefined ? data.DocID : "0")
    .set("MasterID", data.MasterID !== undefined ? data.MasterID : "0");
  const json = XMLrequest(params, data);
  return json;
}

export function SequenceGetParams(data) {
  const params = new Map();
  params
    .set("prefix", "sequence")
    .set("comand", "SequenceGetParams")
    .set("Name", data.Name)
    .set("GetNumMode", data.GetNumMode);
  return AxiosRequest(true, params);
}

export function GetNextNumber(data) {
  const params = new Map();
  params.set("prefix", "sequence").set("comand", "GetNextNumber").set("Name", data.Name).set("Range", data.Range);
  if (data.MaxNumber !== undefined && (data.Range === -4 || data.Range === 4)) params.set("MaxNumber", data.MaxNumber);
  return AxiosRequest(true, params);
}

export async function GetSectionDocs(SectionID) {
  if (!SectionID || SectionID <= 100) return "0";
  const params = new Map();
  params.set("prefix", "documents");
  params.set("comand", "GetSectionDocs");
  params.set("SectionID", SectionID);
  params.set("Simple", "1");
  const json = await AxiosRequest(true, params);
  return json.length ? json[0].DocCfgID : "0";
}

export function GetStaffSettings(SectionID) {
  const params = new Map();
  params.set("prefix", "staff").set("comand", "GetStaffSettings").set("SectionID", SectionID);
  return AxiosRequest(true, params);
}

export async function ApplyTransFilter(SectionID) {
  const params = new Map();
  params.set("prefix", "accounts").set("comand", "ApplyTransFilter").set("SectionID", SectionID);
  return await AxiosRequest(true, params);
}

export async function getAccsParams() {
  const params = new Map();
  params.set("prefix", "accounts").set("comand", "GetTransFilterParams").set("GroupID", "0").set("NeedRefresh", "1");
  return AxiosRequest(true, params);
}

export function getRendHistoryParams(objRef) {
  const params = new Map();
  params
    .set("prefix", "staff")
    .set("comand", "GetRendHistoryParams")
    .set("ObjType", ObjType.RendHistory)
    .set("ObjRef", objRef);
  return AxiosRequest(true, params);
}

export function getPersonGroup(id) {
  const params = new Map();
  params.set("prefix", "persons").set("comand", "GetPersonGroup").set("ID", id);
  return AxiosRequest(true, params);
}
