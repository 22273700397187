import { Container, Grid } from "@mui/material";
import { downloadErrorFile } from "./MainPage/Tools/Tools";

export default function fallbackRender({ error, resetErrorBoundary }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  return (
    <Container maxWidth="xs" style={{ height: window.innerHeight + "px" }}>
      <Grid container direction="column" justifyContent="center" alignItems="center" style={{ marginTop: "50%" }}>
        <Grid item role="alert">
          <p>Что то пошло не так(нажимте F12):</p>
          <pre style={{ color: "red" }}>{error.toString()}</pre>
          <p>Ваша версия сайта: {window.StimateVersion}</p>
          <p>
            Можете скачать файл с данными об ошибках перейдя по{" "}
            <a style={{ cursor: "pointer" }} onClick={downloadErrorFile}>
              ссылке
            </a>
          </p>
        </Grid>
      </Grid>
    </Container>
  );
}
