import { AxiosRequest } from "../../../Url";
import { PostHandleTable } from "../../Tools/Requests";
import { defaultFieldInfo, getDateRusFormatWithId, getModalSectionID, getRightsData } from "../../Tools/Tools";
import { CreateCollapsibleRecords } from "../GridTools/collapsibleRecordsTools";
import { parseColumns } from "../GridTools/parseColumnTools";
import { createGrid, createRecordSource } from "../rcsgrid";

function getMaxDay(id: number, year: number) {
  return (
    {
      0: 0,
      1: 31,
      2: year % 4 === 0 ? 29 : 28,
      3: 31,
      4: 30,
      5: 31,
      6: 30,
      7: 31,
      8: 31,
      9: 30,
      10: 31,
      11: 30,
      12: 31,
    }[id] || 0
  );
}

const defaultCols = [
  {
    fieldName: "CalcRange",
    title: "Период расчёта",
    width: 160,
    collapseRecord: true,
    fieldInfo: {
      Options: "64",
      EditStyle: "",
      Module: "",
      DataType: "",
      ColumnID: "",
      mapId: "",
    },
    OptionsDisable: {
      group: false,
      sort: false,
      filterByField: false,
    },
  },
  {
    fieldName: "Document",
    title: "Документ - основание",
    width: 140,
    hide: true,
    fieldInfo: {
      Options: "32768",
      DataType: "2200",
      EditStyle: "16",
      Module: "",
      ColumnID: "",
      mapId: "",
    },
    OptionsDisable: {
      group: false,
      sort: false,
      filterByField: false,
      link: true,
    },
  },
  {
    fieldName: "Owner",
    title: "Пользователь",
    width: 99,
    fieldInfo: {
      Options: "",
      EditStyle: "64",
      Module: "",
      DataType: "",
      ColumnID: "",
      mapId: "",
    },
    OptionsDisable: {
      group: false,
      sort: false,
      filterByField: false,
    },
  },
  {
    fieldName: "AcceptDate",
    title: "Дата закрытия",
    width: 90,
    fieldInfo: {
      onEditField: true,
      Options: "",
      EditStyle: "2",
      DataType: "",
      ColumnID: "",
      mapId: "",
      tagField: "CalcRange",
      getCustomDefaultTag: (id: string | number) => {
        const year = (Number(id) >> 24) + 1950;
        const month = (Number(id) >> 20) & 0b1111 || 1;
        const day = (Number(id) >> 15) & 0b11111 || getMaxDay(month, year);
        return getDateRusFormatWithId(`${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`).id;
      },
    },
    OptionsDisable: {
      group: false,
      sort: false,
      filterByField: false,
    },
  },
];

interface Props {
  Break: string;
  CLSID: string;
  ID: string;
  Length: string;
  Module: string;
  ObjType: string;
  RequestID?: string;
  SectionID: number;
  Title: string;
  Token: string;
  from?: string;
  func?: string;
}

const chartOfCalculations = async (grid: createGrid, source: createRecordSource, props: Props, data: any) => {
  const onRecordCollapsed = async (event: React.MouseEvent<HTMLElement>) => {
    const recordIndex = event.currentTarget !== undefined ? event.currentTarget.getAttribute("recordindex") : event;
    const params = new Map();
    params
      .set("prefix", props.Module)
      .set("comand", "RecordCollapsed")
      .set("SectionID", props.SectionID)
      .set("ObjType", props.ObjType)
      .set("RecordIndex", recordIndex);
    await AxiosRequest(true, params);
    await grid.refreshSource("!");
  };

  source.onHandleRequest = handleRequest(props);
  //создание стилей для сворачивающихся записей
  source.CreateCollapsibleRecords = CreateCollapsibleRecords;

  await source.open();
  const columns = defaultCols.concat(data.Columns);
  grid.setColumns({ title: columns });
  await grid.setSource(source);
  source.onRecordCollapsed = onRecordCollapsed;
  props.ID ? await grid.refreshSource(props.ID) : await grid.refreshSource();
  await grid.updateGridSize();
};

function handleRequest(props: Props) {
  return function onHandleRequest(request: object) {
    const params = new Map();
    params
      .set("prefix", props.Module)
      .set("comand", "HandleTable")
      .set("ObjType", props.ObjType)
      .set("SectionID", props.SectionID)
      .set("CanChangeActual", "1");
    return AxiosRequest(true, params, request);
  };
}

export default chartOfCalculations;
